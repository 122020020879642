$triangle-base: 0.8em;
$triangle-height: $triangle-base * math.div(sqrt(3), 2);

.faq {
	padding: 50px 0 30px;
	background-color: $semiblack;
	color: $white;
	
	&__title {
		@extend %title;
		color: $gray;

		strong {
			color: $white;
		}
	}
	&__image {
		align-self: flex-start;
		text-align: center;

		&--flash {
			position: relative;
			z-index: 1;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				display: block;
				width: 0;
				height: 0;
				background: none;
				box-shadow: 0px 0px 200px 200px rgba($white, 0.2);
			}
		}
	}
	&__element {
		padding: 10px 0;
	}
	&__question {
		position: relative;
		padding-left: $triangle-base * 2;
		color: $white;
		font-size: 1.5rem;
		line-height: 1.2;
		font-weight: 400;
		cursor: pointer;
		transition: 300ms;

		&::after {
			content: '';
			position: absolute;
			top: 0.2em;
			left: 0;
			display: block;
			border-style: solid;
			border-width: math.div($triangle-base, 2) 0 math.div($triangle-base, 2) $triangle-height;
			border-color: transparent $gray;
			transition: 300ms;
		}

		&:hover {
			&::after {
				left: 0.4em;
			}
		}

		&--active,
		&--active:hover {
			&::after {
				left: 0;
				transform: rotateZ(90deg);
				border-color: transparent $white;
			}
		}
	}
	&__answer {
		$margin-left: math.div($triangle-height * 1.5, 2);
		
		margin-top: 20px;
		margin-left: $margin-left;
		padding-bottom: 10px;
		padding-left: calc(#{($triangle-base * 1.5 * 2) - $margin-left} - 1px);
		border-left: 1px solid rgba($white, 0.75);
	}
}
.faq--relative {
	margin-top: 57px;
	
	.faq {
		&__image {
			position: relative;
			align-self: flex-start;
			top: -100px;
			margin-bottom: -100px;
			
			&::after {
				content: '';
				position: absolute;
				top: -7px;
				right: calc(var(--bs-gutter-x) * .5 + 14px);
				bottom: 7px;
				left: calc(var(--bs-gutter-x) * .5 + 14px);
				display: block;
				border: 2px solid $semiblack;
			}
		}
	}
}
@include media-breakpoint-down(xl) {
	.faq {
		&__question {
			font-size: 1.4rem;
		}
		&__answer {
			$margin-left: math.div($triangle-height * 1.4, 2);
			
			margin-left: $margin-left;
			padding-left: calc(#{($triangle-base * 1.4 * 2) - $margin-left} - 1px);
		}
	}
	.faq--relative {
		.faq {
			&__image {
				top: 0;
				margin-bottom: 0;

				&::after {
					top: 7px;
				}
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.faq {
		&__question {
			font-size: 1.3rem;
		}
		&__answer {
			$margin-left: math.div($triangle-height * 1.3, 2);
			
			margin-left: $margin-left;
			padding-left: calc(#{($triangle-base * 1.3 * 2) - $margin-left} - 1px);
		}
	}
}
@include media-breakpoint-down(md) {
	.faq {
		&__question {
			font-size: 1.2rem;
		}
		&__answer {
			$margin-left: math.div($triangle-height * 1.2, 2);
			
			margin-left: $margin-left;
			padding-left: calc(#{($triangle-base * 1.2 * 2) - $margin-left} - 1px);
		}
	}
}
@include media-breakpoint-down(sm) {
	.faq {
		padding: 30px 0;
		
		&__question {
			font-size: 1.1rem;
		}
		&__answer {
			margin-top: 10px;
			margin-left: 0;
			padding-left: 10px;
			font-size: 0.9rem;
		}
	}
	.faq--relative {
		margin-top: 0;
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.faq {
		&__element {
			padding: 5px 0;
		}
		&__answer {
			margin-top: 5px;
		}
	}
}