.qnt-counter {
	--quantity-counter-color: #{$black};
	--quantity-counter-btn-color: #{$white};
	--quantity-counter-btn-bg: #{$qnt-counter-btn};
	--quantity-counter-input-bg: #{rgba($black, 0.05)};
	
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--quantity-counter-color);
	-webkit-text-fill-color: var(--quantity-counter-color);
	font-size: 18px;

	&__button {
		flex: none;
		width: 0.8em;
		height: 0.8em;
		padding: 0 1px;
		border: none;
		// border-radius: 15%;
		background-color: var(--quantity-counter-btn-bg);
		color: var(--quantity-counter-btn-color);
		-webkit-text-fill-color: var(--quantity-counter-btn-color);
		font-size: 1.75em;
		line-height: 0.86em;
		font-weight: 900;

		&:active {
			padding: 0 0 0 2px;
		}
		&[disabled],
		&[disabled]:active {
			padding: 0 1px;
			opacity: 0.3;
		}
	}
	&__input {
		width: 2.15em; 
		padding: 0.15em 0.15em 0;
		border: none;
		border-radius: 5px;
		background: none;
		text-align: center;
		color: inherit;
		-webkit-text-fill-color: var(--quantity-counter-color);
		// font-size: 1em;
		line-height: 1em;
		min-height: 40px;
		font-size: 1.2em;
		&:focus:focus-visible {
			outline: none;
			background-color: var(--quantity-counter-input-bg);
		}
	}
}
.qnt-counter--inline {
	display: inline-flex;
}
.qnt-counter--small {
	font-size: 14px;
}
.qnt-counter--large {
	font-size: 22px;
}
.qnt-counter--btn-small {
	.qnt-counter {
		&__button {
			// width: 1.2em;
			// height: 1.2em;
			width: 40px;
			height: 40px;
			// font-size: 0.8em; 
			font-size: 2rem;
		}
	}
}
.qnt-counter--relative {
	font-size: 1em;
}
.qnt-counter--clean {
	.qnt-counter {
		&__button {
			background: none;
			color: inherit;
			-webkit-text-fill-color: var(--quantity-counter-color);
			line-height: 0.9em;
			font-weight: inherit;
		}
		&__input {
			font-weight: inherit;
		}
	}
}