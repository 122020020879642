// NAV
$nav-bg:    #000000;
$nav-color: #b4b4b4;
$order-repeated-bg: #aa1d21;

// MENU
$menu-bg:     #ececec;
$menu-border: #e5e5e5;

// NAV CART
$nav-cart-header: #8a8a8a;
$nav-cart-bg:     #e8e8e8; // alfa 90%
$nav-cart-footer: #c8c8c8;
$nav-cart-btn:    #505050;

// QUANTITY COUNTER
$qnt-counter-btn: #505050;

// HEADER
$header-bg-dark:  #7b7b7b;
$header-bg-light: #bebebe;

// GLOBAL
$black:     #000000;
$semiblack: #131514;
$darkgray:  #2d2d2d;
$gray:      #787878;
$lightgray: #e8e8e8;
$semiwhite: #f4f4f4;
$white:     #ffffff;

$orange:        #ee7330;
$second-orange: #ff7e00;
$red:           #94191d;

// BLOG

$blog-black: #434343;
$blog-white: #e3e3e3;

// PACKS

$pack-light-grey: #dddddd;
$pack-grey: #818181;
$pack-light-grey-font: #565656;
$pack-grey-font: #e8e8e8;
