@font-face { /*Roboto 900*/
	font-family: 'Roboto';
	src: url('#{$assets_path}fonts/roboto/roboto-900.woff2') format('woff2'),
	     url('#{$assets_path}fonts/roboto/roboto-900.woff') format('woff'),
	     url('#{$assets_path}fonts/roboto/roboto-900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face { /*Roboto 700*/
	font-family: 'Roboto';
	src: url('#{$assets_path}fonts/roboto/roboto-700.woff2') format('woff2'),
	     url('#{$assets_path}fonts/roboto/roboto-700.woff') format('woff'),
	     url('#{$assets_path}fonts/roboto/roboto-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face { /*Roboto 400*/
	font-family: 'Roboto';
	src: url('#{$assets_path}fonts/roboto/roboto-400.woff2') format('woff2'),
	     url('#{$assets_path}fonts/roboto/roboto-400.woff') format('woff'),
	     url('#{$assets_path}fonts/roboto/roboto-400.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face { /*Roboto 300*/
	font-family: 'Roboto';
	src: url('#{$assets_path}fonts/roboto/roboto-300.woff2') format('woff2'),
	     url('#{$assets_path}fonts/roboto/roboto-300.woff') format('woff'),
	     url('#{$assets_path}fonts/roboto/roboto-300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face { /*Roboto 100*/
	font-family: 'Roboto';
	src: url('#{$assets_path}fonts/roboto/roboto-100.woff2') format('woff2'),
	     url('#{$assets_path}fonts/roboto/roboto-100.woff') format('woff'),
	     url('#{$assets_path}fonts/roboto/roboto-100.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

// ICONS

@font-face {
	font-family: 'Nanocons';
	src:  url('#{$assets_path}fonts/nanocons/Nanocons.eot');
	src: url('#{$assets_path}fonts/nanocons/Nanocons.woff') format('woff'),
		url('#{$assets_path}fonts/nanocons/Nanocons.ttf') format('truetype'),
		url('#{$assets_path}fonts/nanocons/Nanocons.svg') format('svg'),
		url('#{$assets_path}fonts/nanocons/Nanocons.eot#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="nc-"], [class*=" nc-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Nanocons' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// .nc-web:before {
// 	content: "\e9001";
// }
// .nc-language:before {
// 	content: "\e9002";
// }
// .nc-pinterest:before {
// 	content: "\e9003";
// }
// .nc-tiktok:before {
// 	content: "\e9004";
// }
// .nc-youtube:before {
// 	content: "\e9005";
// }
// .nc-basket:before {
// 	content: "\e9006";
// }
// .nc-basket-plus:before {
// 	content: "\e9007";
// }
// .nc-facebook:before {
// 	content: "\e9008";
// }
// .nc-instagram:before {
// 	content: "\e9009";
// }
// .nc-phone:before {
// 	content: "\e9010";
// }
// .nc-email:before {
// 	content: "\e9011";
// }

.nc-search:before {
	content: "\e900";
  }

.nc-basket:before {
	content: "\e900";
}

.nc-basket-plus:before {
	content: "\e901";
}

.nc-facebook:before {
	content: "\e902";
}

.nc-instagram:before {
	content: "\e903";
}

.nc-language:before {
	content: "\e904";
}

.nc-pinterest:before {
	content: "\e905";
}

.nc-search:before {
	content: "\e906";
}

.nc-tiktok:before {
	content: "\e907";
}

.nc-web:before {
	content: "\e908";
}

.nc-youtube:before {
	content: "\e909";
}