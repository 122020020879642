.header {
	position: relative;
	padding-top: 86px;
	
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
		display: block;
		height: 1000px;
		background: $white;
		background: linear-gradient(to bottom, $lightgray 0%, rgba($lightgray,0) 100%);
	}
	
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	&__title {
		font-size: 3rem;
		line-height: 1;
		font-weight: 100;
		text-transform: uppercase;

		strong {
			font-weight: 900;
		}
	}
	&__border {
		position: relative;
		margin: 50px 0 0;
		padding: 0;

		&::before {
			content: '';
			display: block;
			margin-left: 40px;
			width: 90px;
			height: 7px;
			background-color: $black;
		}
		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			height: 1px;
			background-color: $black;
		}
	}
	&__content {
		color: $white;

		h2 {
			font-size: 3rem;
			line-height: 1.2;
			font-weight: 100;
			text-transform: uppercase;
		}
		p {
			margin: 0;
			font-size: 1.15rem;
			line-height: 1.2;

			&.big {
				font-size: 1rem;
				line-height: 1.5;
				font-weight: 300;
			}
		}
	}
	&__product {
		display: flex;
		align-items: center;
	
	}
	&__image {
		position: relative;
		z-index: 1;
		text-align: center;
		max-height:615px; 
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: -1;
			display: block;
			width: 0;
			height: 0;
			background: none;
			box-shadow: 0px 0px 200px 200px rgba($white, 0.3);
		}
	}
	&__product-name {
		display: block;
		margin: 0;
		font-size: 1.3rem;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		
		strong {
			display: block;
			padding-top: 10px;
			font-size: 3rem;
			line-height: 0.9;
			font-weight: 100;
			letter-spacing: 0.15em;
		}
	}
	&__product-slogan {
		position: relative;
		display: block;
		margin: 15px 0 0 0;
		padding-top: 15px;
		font-size: 1.2rem;
		line-height: 1;
		font-weight: 700;

		h1 {
			font-size: unset;
			display: inline;
			font-weight: unset;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 30%;
			height: 2px;
			background-color: $black;
		}
	}
	&__actions {
		margin-top: 35px;
		max-width: 500px;
	}

	&--blog {
		.search {
			position: relative;  
			display: grid; 
			grid-template-columns: 6fr 1fr;
			border-color: $gray;
			border-style: solid;
			border-width: 1px;
			border-radius: 0;
			background-color: inherit;
			& * {
				background-color: inherit;
			}
			&__input {
				padding: 1px 10px 1px 10px;
				height: 50px;
				border: none;
				&:active, &:hover, &:focus {
					outline: 0;
					outline-offset: 0;
				}
			}
			&__button {
				border: none;
			}
		}
	}

	&--blog & {
		&__title {
			font-size: 2.5rem;
			text-transform: unset;
			font-weight: 400;
			&:after {
				content: '';
				margin-top: 20px;
				background-color: $black;
				height: 4px;
				width: 120px;
				display: block;
			}
		}
	}

	&--blog {
		.header {
			&__container {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}
	}
}
.header--border {
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		right: 0;
		bottom: -70px;
		left: 0;
		display: block;
		background-color: rgba($black, 0.1);
		height: 100px;
		transform: rotateZ(0.8deg);
		content: none;
	}

	.header {
		&__container {
			min-height: 560px;
		}
	}
}
.header--home {
	background-color: $header-bg-dark;
	background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xxl.avif');
	background-size: auto 780px;
	background-repeat: no-repeat;
	background-position: calc(50% + 330px) bottom;
	// background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xl.jpg');
	&__title {
		position: relative;
		padding-left: 25px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 5px;
			bottom: 5px;
			left: 0;
			width: 2px;
			height: auto;
			margin: 0;
			background: #fff;
		}
		&__content {
			padding-left: 25px;
		}
	}
}

.NoSupportAvif {
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xxl.webp');
	}
}

.NoSupportWebp {
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xxl.jpg');
	}
}

.header--product {
	background-color: #e8e8e8;
}
@include media-breakpoint-down(xxl) {
	.header {
		&__title {
			font-size: 2.5rem;
		}
		&__product-name {
			strong {
				font-size: 3.5rem;
			}
		}
		&__actions {
			margin-top: 50px;
		}
	}
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xl.avif');
		// background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xl.jpg');
		background-position: calc(50% + 360px) bottom;
	}
	.NoSupportAvif {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xl.webp');
		}
	}
	.NoSupportWebp {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@xl.jpg');
		}
	}
}
@include media-breakpoint-down(xl) {
	.header {
		&__content {
			h2 {
				font-size: 2.2rem;
				line-height: 1.2;
			}
		}
		&__product-name {
			strong {
				font-size: 3.2rem;
			}
		}
	}
	.header--border {
		.header {
			&__container {
				min-height: 450px;
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}
	}
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@lg.avif');
		background-size: auto 660px;
		background-position: calc(50% + 300px) bottom;
		// background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@lg.jpg');
	}

	.NoSupportAvif {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@lg.webp');
		}
	}

	.NoSupportWebp {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@lg.jpg');
		}
	}
}
@include media-breakpoint-down(lg) {
	.header {
		padding-top: 75px;

		&__container {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		&__content {
			h2 {
				font-size: 1.85rem;
			}
		}
		&__product-name {
			font-size: 1.2rem;
			
			strong {
				font-size: 2.6rem;
			}
		}
		&__product-slogan {
			font-size: 1.1rem;
		}
	}
	.header--border {
		.header {
			&__container {
				min-height: 320px;
				padding-top: 30px;
			}
		}
	}
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@md.avif');
		background-image: image-set(
			url('#{$assets_path}img/home-header-woman/home-header-woman@md.avif') 1x,
			url('#{$assets_path}img/home-header-woman/home-header-woman@md@2x.avif') 2x
		);
		background-size: auto 470px;
		background-position: calc(50% + 250px) bottom;
		// background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@md.jpg');
		// background-image: image-set(
		// 	url('#{$assets_path}img/home-header-woman/home-header-woman@md.jpg') 1x,
		// 	url('#{$assets_path}img/home-header-woman/home-header-woman@md@2x.jpg') 2x
		// );
	}
	.NoSupportAvif {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@md.webp');
			background-image: image-set(
				url('#{$assets_path}img/home-header-woman/home-header-woman@md.webp') 1x,
				url('#{$assets_path}img/home-header-woman/home-header-woman@md@2x.webp') 2x
			);
		}
	}
	.NoSupportWebp {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@md.jpg');
			background-image: image-set(
				url('#{$assets_path}img/home-header-woman/home-header-woman@md.jpg') 1x,
				url('#{$assets_path}img/home-header-woman/home-header-woman@md@2x.jpg') 2x
			);
		}
	}
}
@include media-breakpoint-down(md) {
	.header {
		padding-top: 60px;

		&__container {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		&__border {
			margin-top: 30px;
		}
		&__content {
			h2 {
				font-size: 1.5rem;
			}
		}
		&__title {
			font-size: 2rem;
		}
		&__order {
			margin-top: 30px;
		}
		&__image {
			img {
				width: 400px;
			}
		}
		&__product-slogan {
			display: none;
		}
		&__actions {
			margin-top: 30px;
			max-width: 100%;
		}
	}
	.header--border {
		.header {
			&__container {
				min-height: 240px;
			}
		}
	}
	.header--home {
		background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@sm.avif');
		background-image: image-set(
			url('#{$assets_path}img/home-header-woman/home-header-woman@sm.avif') 1x,
			url('#{$assets_path}img/home-header-woman/home-header-woman@sm@2x.avif') 2x
		);

		background-size: auto 350px;
		background-position: calc(50% + 190px) bottom;

		// background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@sm.jpg');
		// background-image: image-set(
		// 	url('#{$assets_path}img/home-header-woman/home-header-woman@sm.jpg') 1x,
		// 	url('#{$assets_path}img/home-header-woman/home-header-woman@sm@2x.jpg') 2x
		// );
	}
	.NoSupportWebp {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@sm.webp');
			background-image: image-set(
				url('#{$assets_path}img/home-header-woman/home-header-woman@sm.webp') 1x,
				url('#{$assets_path}img/home-header-woman/home-header-woman@sm@2x.webp') 2x
			);
		}
	}
	.NoSupportWebp {
		.header--home {
			background-image: url('#{$assets_path}img/home-header-woman/home-header-woman@sm.jpg');
			background-image: image-set(
				url('#{$assets_path}img/home-header-woman/home-header-woman@sm.jpg') 1x,
				url('#{$assets_path}img/home-header-woman/home-header-woman@sm@2x.jpg') 2x
			);
		}
	}
}
@include media-breakpoint-down(sm) {
	.header {
		padding-top: 47px;

		&__title {
			font-size: 1.7rem;
		}
		&__border {
			&::before {
				margin-left: 30px;
				width: 80px;
			}
		}
		&__image {
			img {
				width: 100%;
			}
		}
		&__product-name {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
			font-size: 1rem;
			
			strong {
				font-size: 2.2rem;
			}
		}
		&__actions {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
		}

	}
	.header--home {
		background-position: calc(80% + 70px) bottom;
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.header {
		&__container {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&__title {
			font-size: 1.4rem;
		}
		&__border {
			margin-top: 20px;
		}
		&__image {
			img {
			//	width: 250px;
			}
		}
		&__product-name {
			strong {
				padding-top: 0;
				font-size: 1.75rem;
			}
		}
		&__actions {
			margin-top: 20px;
		}
	}
	.header--border {
		.header {
			&__container {
				padding-top: 20px;
			}
		}
	}
}

.header-content {
	background-color: $lightgray;

	h2 {
		margin-bottom: 1rem;
	}
	p {
		margin: 0;
		font-weight: 400;
	}
}


.image_variant__block {
	position: relative;
}

@media(max-width: 575.98px) {
	.image_variant__block {
		max-width: 400px;
		//margin: auto;
	}
}


@media ( max-width: 767.98px ) {
	.header__product {
		&-slogan {
			display: block;
		}
	}
}

.header__product {
	&-capacity {
		p {
			margin-bottom: 0;
			span {
				display: block;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			gap: 0 5px;
			list-style: none;
			padding: 0;
		}
	}
}

@media ( max-width: 575.98px ) {
	.header__product {
		&-slogan {
			display: block;
			max-width: 400px;
			margin: 15px auto;
		}
		&-capacity {
			display: block;
			max-width: 400px;
			margin: 15px auto;
			ul {
				display: flex;
				flex-wrap: wrap;
				gap: 0 5px;
				list-style: none;
			}
		}
	}
}

.header--home {
	background-color: #7d7d7d;
	.header {
		&__content {
			h2, .h2 {
				font-size: 2.5rem;
			}
			p.big {
				margin-left: 25px;
			}
		}
		&--border {
			content: none;
		}
	}
	&__list {
		padding: 20px 20px 20px 45px;
		background: #222;
		background: linear-gradient(to right, #222 0%, #6f6f6f 100%);
		list-style: square;
		li {
			font-size: 1rem;
			line-height: 1.5;
			padding-left: 4px;
		}
	}
	&__border {
		background-color: #222;
		.icon-block {
			display: flex;
			align-items: center;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		img {
			width: 100px; 
			height: auto;
		}
		span {
			color: #fff;
		}
	}
}


@media screen and (max-width: 1199.98px) {
	.header--home {
		background-color: #7d7d7d;
		.header {
			&__content {
				h2, .h2 {
					font-size: 2rem;
				}
			}
		}	
	}
}

@media screen and (max-width: 991.98px) {
	.header--home {
		background-color: #7d7d7d;
		.header {
			&__content {
				h2, .h2 {
					font-size: 1.75rem;
					background: #222;
					background: linear-gradient(to right, #222 0%, #6f6f6f 100%);
					padding-top: 10px;
					padding-bottom: 10px;
					&:before {
						content: none;
					}
				}
			}
		}	
	}
	.icon-block {
		margin-left: auto;
		margin-right: auto;
	}
	.header--home__border img {
		width: 75px;
	}
	.header--home__border span {
		font-size: 0.75rem;
	}
}
@media screen and (max-width: 767.98px) {
	.header__content h2, .header__content .h2 {
		font-size: 1.25rem;
	}
	.header--home__border img {
		width: 50px;
	}
	.header-content .header--home__title {
		padding-left: unset;
		font-size: 1.5rem;
	}
	.header-content .header--home__title:before {
		content: none;
	}
}

.main_product.animate {
	img {
		opacity: 1;
		transition: opacity 0.25s ease-in;
	}

	&.animate {
		img {
			opacity: 0.1;
			transition: opacity 0.1s ease-out;
		}
	}

}




.header__image.slider {
	display: grid;
	grid-template-columns: 1fr 5fr;
	padding-bottom: 10px;

	.image_variant__block {
		position: relative;
		z-index: -1;
	}

	.slider {
		position: relative;
		z-index: 1;
		.dots {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;
			gap: 20px;
			
    		overflow-y: auto;
    		-webkit-overflow-scrolling: touch;
    		scrollbar-width: none;
    		max-height: initial;
		}
		.dot {
			cursor: pointer;
			border: rgba(18, 18, 18, 0.3) solid 1px;
			width: max-content;
			img {
				pointer-events: none;
			}
			&.active {
				border-color: rgba(18, 18, 18, 1);
				border-width: 2px;
			}
		}
	}

	.slides {
		width: 100%;
		height: 100%;
		position: relative;
		.slide {
			position: absolute;
			opacity: 0;
			transition: 0.3s;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			width: 530px;
			img {
				max-width: 100%;
			}
			&.active {
				opacity: 1;
				position: relative;
			}
		}
	}

	.main_product.slider {
		.prev, .next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 3rem;
			height: 3rem;
			font-size: 3rem;
			color: #000;
			display: flex;
			align-items: center;
			cursor: pointer;
			opacity: 1;
			transition: opacity 0.25s ease-in;
		}
	}

	.prev, .next {
		width: 0 !important;
		height: 0 !important;
		font-size: 0;
		transition: opacity 0.25s ease-out;
		opacity: 0;
	}

	.prev {
		left: 0;
		justify-content: flex-start;
		&:before {
			content: '<';
		}
	}

	.next {
		right: 0;
		justify-content: flex-end;
		&:after {
			content: '>';
		}
	}
}

@media screen and (max-width: 1399.98px) {
	.header__image.slider {
		.slider {
			.dot {
				max-width: 95px;
			}
		}

		.slides {
			.slide {
				width: 451px;
				height: 514px;
			}
		}
	} 
}

@media screen and (max-width: 1199.98px) {
	.header__image.slider {
		.slider {
			.dot {
				max-width: 80px;
			}
		}

		.slides {
			.slide {
				width: 376px;
				height: 428px;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.dots{
		height: 605px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {

	.header__image.slider {
		display: unset;
		position: relative;

		.image_variant__block {
			width: 280px;
			margin-left: auto;
		}

		.slider {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			.dots {
				gap: 5px;
				justify-content: space-around;
			}
			.dot {
				max-width: 60px;		
				border-width: 2px;
			}
		}

		.slides {
			.slide {
				width: 285px;
				height: auto;
			}
		}
	}
}

@media screen and (max-width: 575.98px) {
	.header__image.slider  {
		grid-template-columns: 5fr 1fr;
		max-height:425px;
		margin-bottom:1.5rem;
		.slider {
			grid-row-start: 1;
			grid-column-start: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: end;
			margin-left: auto;
			height: 90vw;
			overflow-y: auto;
			.dots {
				gap: 15px;
			}

			.dot {
				max-width: 65px;
			}
		}
		
		.slides {
			.slide {
				width: 100%;
				height: 345px;
			}
		}

		.image_variant__block {
			grid-column-start: 1;
			grid-row-start: 1;
			max-height:390px;
			height:100%;
		}
	}
}

@media screen and (max-width: 400.98px) {
	.header__image.slider {
		.slider {
			.dots {
				gap: 10px;
			}
			.dot {
				max-width: 55px;
			}
		}
		
		.slides {
			.slide {
				width: 250px;
				height: 300px;
			}
		}
	}
}