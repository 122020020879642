.mark {
    &-section {
        padding-bottom: 40px;
    }
    $padding-left: 15%;
    &-title {
        font-size: 1.8rem;
        position: relative;
        padding: 1.5rem 1rem;
        margin-bottom: 1rem;
        padding-left: $padding-left;
        width: max-content;
        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 10%;
            height: 3px;

        }
    }
    &-container {
        margin-left: auto;
        margin-right: 5%;
        padding-top: 40px;
        position: relative;
        &.dark {
            color: #f3f3f3;
            .mark-title {
                &:before {
                    background-color: #afafaf;
                }
            }
            &:before {
                background-color: #000000;
            }
        }
        &.light {
            .mark-title {
                &:before {
                    background-color: #000000;
                }
            }
            &:before {
                background-color: #E9E9E9;
            }
            color: #000000;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 5%;
            left: 10%;
            right: -100%;
            bottom: 50%;
            z-index: -1;
        }
    }
    &-content {
        margin-left: auto;
        text-align: justify;
        padding-left: $padding-left;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        * {
            color: inherit;
        }

    }
    &-images {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        align-items: flex-start;
        img {
            max-width: 145px;
        }
        .image--one {
            padding-top: 50px;
        }
    }
    &-icons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 1.25rem;
        color: #000000;
        margin-top: 15px;
        gap: 5px 10px;
        .icons {
            &-item {
                display: grid;
                grid-template-columns: 12px 1fr;
                align-items: center;
                gap: 5px;
            }
            &-icon {
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid #000000;
                background-color: $black;
            }
        }
    }
}

@media screen and (min-width:400px) {
    .mark {
        &-images {
            justify-content: flex-end;
        }
    }
}

@media screen and (min-width:576px) {
    .mark {
        &-block {
            &:first-child {
                margin-bottom: 2rem;
            }
        }
        $padding-left: 0;
        &-title, &-content {
            padding-left: $padding-left;
        }
        &-container {
            align-items: center;
            position: relative;
            display: grid;
            padding: 80px 0 0;
            grid-template-columns: 1fr auto;
            gap: 0 15px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 15%;
                bottom: 0;
                z-index: -1;
            }
            &.dark {
                .mark {
                    &-container {
                        &:before {
                            background-color: #000000;
                        }
                    }
                }
            }
            &.light {
                .mark {
                    &-container {
                        &:before {
                            background-color: #E9E9E9;
                        }
                    }
                }
            }
            &.left {
                &:before {
                    left: 0%;
                    right: 5%;
                }
                .mark {
                    &-text {
                        grid-column: 1/2;
                        padding-left: 5%;
                    }
                    &-images {
                        grid-column: 2/3;
                    }
                }
            }
            &.right {
                &:before {
                    left: 5%;
                    right: 0;
                }
                .mark {
                    &-text {
                        grid-column: 2/3;
                        padding-right: 5%;
                    }
                    &-images {
                        grid-column: 1/2;
                    }
                }
            }
        }
        &-text {
            position: relative;
            padding: 5% 0;

        }
        &-title {
            margin-bottom: 0;
            padding: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;
            align-self: center;
            &:before {
                bottom: 0;
                right: 50%;
                height: 2px;
                background-color: #fff;
            }
        }
        &-content {
            align-self: stretch;
        }
        &-images {
            position: relative;
            margin-top: unset;
            grid-column: 2/3;
            grid-row: 1/2;
            max-height: 100%;
            img {
                position: relative;
                display: block;
                box-shadow: 1px 1px 10px #000;
                width: auto;
            }
            .image {
                &--one {
                    left: 0;
                    padding-top: unset;
                    top: 25px;
                }
                &--two {
                    top: -25px;
                }
            }
        }
        &-icons {
            margin-top: 5px;
            font-size: 1.2rem;
            grid-template-columns: 1fr;

        }
    }
}

@media screen and (min-width:768px) {
    .mark {
        &-title {
            &:before {
                left: -10px;
            }
        }
        &-container {
            margin-right: unset;
            padding-top: 50px;
        }
        &-content {
            font-size: 1rem;
        }
        &-icons {
            font-size: 1rem;
            grid-template-columns: 1fr 1fr;
            .icons {
                &-icon {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
        &-images {
            img {
                max-width: 180px;
            }
        }
    }
}

@media screen and (min-width:992px) {
    .mark {
        &-container {
            padding-top: 100px;
            margin-bottom: 20px;
            grid-template-columns: 1fr 1fr;
        }
        &-content {
            font-size: 1.5rem;
            text-align: left;
        }
        &-title {
            font-size: 2rem;
        }
        &-images {
            justify-content: center;
            img {
                max-width: 220px;
                height:100%;
            }
            .image {
                &--one {
                    padding-top: unset;
                    top: 30px;
                }
                &--two {
                    top: -70px;
                }
            }
        }
        &-icons {
            margin-top: unset;
            align-self: center;
            width: 50%;
            &.left {
                margin-right: auto;
            }
            &.right {
                margin-left: auto;
            }
            .icons {
                &__item {
                    font-size: 20px;
                    gap: 10px;
                }
            }
        }
    }
}

@media screen and (min-width:1200px) {
    .mark {
        &-icons {
            font-size: 1.25rem;
            width: 40%;
            .icons {
                &-item {
                    gap: 1rem;
                }
                &-icon {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }
        &-images {
            img {
                max-width: 320px;
            }
        }
        &-text {
            padding-top: 30px;
        }
        &-title {
            font-size: 2.25rem;
            padding-bottom: 15px;
            margin-bottom: 20px;
            &:before {
                left: -20px;
            }
        }
        &-content {
            font-size: 20px;
            line-height: 35px;
            line-height: 35px;
            font-weight: 300;
            * {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
}

@media screen and (min-width:1400px) {
    .mark {
        &-icons {
            width: 50%;
        }
        &-title {
            padding-bottom: 20px;
            margin-bottom: 30px;
            font-size: 2.5rem;
            &:before {
                height: 3px;
            }
        }
        &-icons {
            font-size: 1.2rem;
            gap: 10px 20px;
        }
    }
}

@media screen and (min-width:1600px) {
    .mark {
        &-section {
            .container {
                max-width: 1540px;
            }
        } 
        &-container {
            &.left {
                padding-left: 100px;
            }
            &.right {
                padding-right: 100px;
            }
        }
    }
}
@media screen and (max-width:576px) {
    .mark-title {
        font-size:1.1rem;
    }
    .mark-container {
        padding-top:10px;
    }
    .mark-content {
        font-size:0.8rem;
    }
    .mark-container:before{
        bottom:45%;
    }
    .mark-icons .icons-item {
        font-size: 0.8rem;
    }
    .mark-icons .icons-icon{
        width: 0.75rem;
        height: 0.75rem;
    }
    .mark-images .image--one {
        height:100%
    }
}