@import '../includes/swiper';

.socials {
    margin-top: 3rem;
    position: relative;
    //z-index: 100;
    &__title {
        font-size: 2.5rem;
        font-weight: 300;
        text-align: center;
        color: #191919;
        padding: 1rem;
    }
    &__wall {
        margin: auto;
        background-color: #F2F2F2;
    }
    &__links {
        display: grid;
        align-items: center;
        justify-items: center;
        width: 40%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        gap: 20px;
        margin: auto;
        &__title {
            font-size: 24px;
            line-height: 1.2;
            grid-column: 1/3;
            text-align: center;
            letter-spacing: 0.7;
            font-weight: 300;
        }
        &__follow {
            grid-column: 1/2;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            img {
                display: block;
                max-width: 100%;
            }
        }
        &__links {
            top: -5px;
            position: relative;
            grid-column: 2/3;
            width: 100%;
            display: flex;
            gap: 10px;
            justify-content: flex-start;
            img {
                display: block;
                width: 45px;
                height: auto;
            }
        }
    }
    &__slider {
        position: relative;
        display: flex;
        margin: auto;
        align-items: center;
        overflow: hidden;
        width: 95%;
      
    }
    &__slides {
        display: flex;
        gap: 10px;
        width:235px;
        height:235px;
        &.animating {
            .socials__slide {
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
                &.slide-in {
                    animation-name: slideIn;
                }
                &.slide-out {
                    animation-name: slideOut;
                }
            }
        }
    }
    &__slide {
        flex-shrink: 0;
        flex-grow: 0;
        height: auto;
        position: relative;
        cursor: pointer;
        &--animate {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
    }
    &__control {
        position: absolute;
        z-index: 2;
        width: 50px;
        border: none;
        height: 50px;
        background-color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        opacity: 0.75;
        transition: opacity 300ms ease-in;
        touch-action: manipulation;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
        &:before, &:after {
            content: "";
            display: block;
            background-color: #777;
            position: absolute;
            top: 50%;
            width: 20px;
            height: 3px;
            transform-origin: center;
        }
    }
    &__prev {
        left: 5px;
        &:before {
            transform: rotate(-45deg) translateX(45%);
            right: 25px;
        }
        &:after {
            transform: rotate(45deg) translateX(45%);
            right: 25px;
        }
    }
    &__next {
        right: 5px;
        &:before {
            transform: rotate(45deg) translateX(-45%);
            left: 25px;
        }
        &:after {
            transform: rotate(-45deg) translateX(-45%);
            left: 25px;
        }
    }
    &__hash{
        grid-column: 1/span 2;
        display: flex;
        width: 30%;
        justify-content: center;
    }
    &_img {
        &_title {
            text-align: center;
        }
    }
    &__img {
        &_title {
            width:30%;
            padding:2rem 0;
        }
    }
}

.socials__video {
    position: fixed;
    top: 30px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
}
.socials__slide.swiper-slide {
    width:100%!important;
}
@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.socials__video {
    display: flex;
    align-items: center;
    justify-content: center;
}
.video__wrapper {
    max-width: 100%;
    position: relative;
}
.video-container {
    width: 25%;
    height: 70%;
}

.socials__video .socials__control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.video-block {
    overflow: unset !important;
}

.video-block video {
    max-height: 100%;
    width: auto;
}

.socials__close {
position: absolute;
top: 50px;
right: 50px;
border: none;
background: none;
color: white;
font-size: 50px;
cursor: pointer;
}

@media screen and (max-width: 767.98px) {
    .video-container {
        width: 100%;
    }
    .socials__video .socials__control.socials__prev {
        left: 0;
    }
    .socials__video .socials__control.socials__next {
        right: 0;
    }
    .video-block video {
        display: block;
        margin: auto;
    }
    .socials {
        &__links {
            width:100%;
        }
        &__img{
            &_title{
                width:50%;
                padding:3rem 0;
            }
        }
        &__hash {
            width: 40%;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .video-container {
        width: 50%;
        height: 70%;
    }
    .socials__control.socials__prev {
        left: -125px;
    } 

    .socials__control.socials__next {
        right: -125px;
    } 
}

@media screen and (min-width: 992px) {
    .socials__video .socials__control.socials__prev {
        left: -250px;
    } 

    .socials__video .socials__control.socials__next {
        right: -250px;
    } 
}

@media screen and (min-width: 768px){
    .socials {
        &__title {
            font-size: 36px;
        }
        &__links {
            gap: 30px;
            &__links {
                top: 0px;
                gap: 20px;
            }
        }
    }
}

@media screen and (min-width: 992px){
    .socials {
        &__wall {
            width: 100%;
        } 

        &__slide {
         //   max-width: 30%;
            &:hover {
                .socials__slide {
                    &--animate {
                        z-index: 1;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 567px){
    .socials__img_title {
        width:70%;
        padding:2rem 0;
    }
    .social-media-section a {
        font-size:1.5rem;
    }
    .socials__links .flex-wrap {
        gap:10px;
        flex-direction: column;
        align-items: center;
        img {
            height: 50px;
        }
        .social-media-section {
            gap : 0 30px;
        }
    } 
    .socials__links {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        gap: 20px 0;
    }
}