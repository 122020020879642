.parallax {
	position: relative;
	overflow: hidden;

	&__layer {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> * {
			display: block;
		}
	}
}