@use 'sass:math';

.products-grid {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: $lightgray;
	&__image {
		img {
			height: auto;
		}
	}
	&__more {
		display: block;
    padding: 10px 10px;
    width: 100%;
    width: 100%;
	text-decoration: none;
	color:#000;
		p {
			margin:0;
		}
	}
	&__promo {
		
        padding:5% 0 0 0;
        font-size:0.9rem;
        &-price {
            font-weight: 700;
        }
	}
	&__slider-nav {
		display: none;
	}
	&__element {
		padding-top: calc(var(--bs-gutter-x) * .5);
		padding-bottom: calc(var(--bs-gutter-x) * .5);
		font-size: 1rem;

		&:focus {
			outline: none;
		}

		&--group,
		&--unavailable {
			.products-grid {
				&__actions {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 47px;
					text-align: center;
					text-transform: uppercase;
					text-decoration: none;
				}
			}
		}
		&--group {
			.products-grid {
				&__actions {
					&:hover {
						background-color: rgba($black, 0.05);
						color: $black;
					}
				}
			}
		}
		&--unavailable {
			.products-grid {
				&__image,
				&__details,
				&__actions {
					opacity: 0.5;
				}
			}
		}
	}
	
	&__content {
		position: relative;
		display: flex;

		&:hover {
			.products-grid__backdrop {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&__link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		display: block;
		cursor: pointer;
	}
	&__backdrop {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		transition: 300ms;
	}
	&__image {
		flex: 0 0 60%;
		text-align: center;
	}
	&__details {
		flex: 0 0 35%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__name {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 1.2;
		font-weight: 700;
	}
	&__description {
		font-size: 1.1rem;
		line-height: 1.2;
		font-weight: 400;
		span.percent {
			display: block;
			color:red;
			font-size:0.8em;
		}
	}
	&__actions {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		gap: calc(var(--bs-gutter-x) * .5);
		padding: 5px 20px;
		border: 1px solid $black;
		color: $black;
		min-height:71px;
		> * {
			order: 1;
		}
	}
	&__colors {
		$triangle-base: 12px;
		$triangle-height: $triangle-base * math.div(sqrt(3), 2);
		
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: stretch;
		
		button {
			position: relative;
			padding-left: $triangle-height;
			padding-right: $triangle-height * 3;
			border-style: solid;
			border-width: 0 1px;
			border-color: $black;
			background: none;

			&:hover {
				&::after {
					right: $triangle-height - 2px;
				}
			}

			&::after {
				content: '';
				position: absolute;
				top: calc(50% - math.div($triangle-base, 2));
				right: $triangle-height;
				display: block;
				border-style: solid;
				border-width: math.div($triangle-base, 2) 0 math.div($triangle-base, 2) $triangle-height;
				border-color: transparent $gray;
				transition: 300ms;
			}
		}
		ul {
			// Yurii

			// position: absolute;
			// top: calc(100% + 5px);
			// left: -1px;
			// z-index: 5;
			// display: none;
			// min-width: calc(100% + 2px);
			// padding: 5px $triangle-height;
			// background-color: $lightgray;
			// box-shadow: 0px 15px 20px -5px rgba($black, 0.1);
			// list-style-type: none;

			display: flex;
			gap: 0 5px;
			margin: 0;
			padding: 0;
			position: relative;
			box-shadow: none;
			top: unset;
			left: unset;
			min-width: unset;
		}
		li {
			margin: 0.5em 0;
			font-size: 14px;

			&:hover {
				background-color: rgba($black, 0.05);
			}
		}

		&--expanded {
			button {
				&:hover {
					&::after {
						right: $triangle-height;
					}
				}

				&::after {
					transform: rotateZ(90deg);
				}
			}
			ul {
				display: block;
			}
		}
	}
	&__color {
		display: flex;
		align-items: center;
		gap: 0.5em;
		text-align: left;
		font-size: 1em;
		line-height: 1;
		white-space: nowrap;
		cursor: pointer;
			
		&--unavailable {
			cursor: default !important;

			&:hover {
				background: none !important;
			}
		}

		.variant-color {
			font-size: 1.5em;
		}
	}
	&__price {
		text-align: center;
		display: flex;
		flex-direction: inherit;
		align-items: center;
	}
	&__quantity {
		text-align: center;
		font-size: 18px;
	}
	&__button {
		// Yurii
		position: relative; 
		button {
			display: flex;
			padding: 0 1px;
			border: none;
			background: none;
			color: $black;
			&:active {
				padding: 0 0 0 2px;
			}

			&[disabled] {
				// Yurii
				opacity: 1;

				// opacity: 0.25;

				&:active {
					padding: 0 1px;
				}
			}
			
			> span {
				display: none;
			}
			> i {
				font-size: 32px;
			}
		}
		// Yurii
		&--mask {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			cursor: pointer;
		}
		.disabled-mask {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 10;
			cursor: default;
		}
	}
	// Yurii
	.button-block {
		position: relative;
	}
	.color-alert {
		position: absolute;
		top: -50px;
		right: 0;
		padding: 7px 14px;
		border: 1px #000 solid;
		background-color: #fff;
		display: none;
		color: #000;
		z-index: 20;
		&--main {
			min-width: 130px;
			text-align: center;
			left: 0 !important;
			width: max-content;
		}
		&:after {
			content: "";
			width: 12px;
			height: 12px;
			bottom: -7px;
			left: 18px;
			display: block;
			position: absolute;
			background-color: #fff;
			border-bottom: 1px #000 solid;
			border-right: 1px #000 solid;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.add-to-cart {
	&__packs {
		grid-area: packs;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		gap: 15px;
		max-width: 100%;
		padding: 10px 0;
		&-list {
			margin-left: auto;
			display: flex;
			gap: 6px 6px;
		}
	}
	&__promo {
        grid-area:promotion;
        padding:2%;
        font-size:0.9rem;
        &-price {
            font-weight: 700;
        }
    }
	&__pack-button {
		position: relative;
		font-size: 0.8rem;
		font-weight: 900;
		width: 2em;
		height: 2em;
		display: flex;
		cursor: pointer;
		background-color: $pack-light-grey;
		color: $pack-light-grey-font;
		justify-content: center;
		align-items: center;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: transparent;
		}
		&.active {
			background-color: $pack-grey;
			color: $pack-grey-font;
			&:before {
				top: -0.12em;
				right: -0.12em;
				bottom: -0.18em;
				left: -0.12em;
				border: 1px solid rgba(0,0,0,.75);
			}
		} 
	}
}
*:lang('id-ID')
{
	.products-grid__actions {
		gap: calc(var(--bs-gutter-x)* .2);
	}
}
.change_color{
	font-size:0.8rem;
}

@include media-breakpoint-up(xxl) {
	.products-grid {
		&__image {
			flex: 0 0 50%;
			
			img {
				width: 140px;
			}
		}
		&__details {
			flex: 0 0 50%;
		}
		&__actions {
			padding: 5px 10px;
		}
	}
}
@include media-breakpoint-down(xl) {
	.products-grid {
		&__details {
			flex: 0 0 40%;
		}
	}
}
@include media-breakpoint-down(lg) {
	.products-grid {
		padding-top: 10px;
		padding-bottom: 10px;
		
		&__element {
			display: flex;
			flex-direction: column;
			font-size: 0.8rem;

			&--group,
			&--unavailable {
				.products-grid {
					&__actions {
						min-height: 87px;
						padding: 5px 15px;
						border: 1px solid $black;
						font-size: 1.3em;
						line-height: 1;
					}
				}
			}
		}

		&__backdrop {
			display: none;
		}
		&__image {
			img {
				max-width: 100px;
			}
		}
		&__content {
			flex-direction: column;
		}
		&__details {
			flex: none;
			margin-top: 10px;
			margin-bottom: 15px;
		}
		&__name {
			text-align: center;
			font-size: 1.2rem;
			line-height: 1;
		}
		&__description {
			text-align: center;
			font-size: 0.9rem;
			line-height: 1;
		}
		&__actions {
			display: grid;
			grid-template-columns: repeat(2, minmax(max-content, 1fr));
			grid-template-areas:
				"packs    packs"
				"colors    colors"
				"quantity  price"
				"button    button";
			gap: 5px 0;
			align-items: stretch;
			
			margin-top: auto;
			padding: 0;
			border: none;
		}
		&__colors {
			grid-area: colors;
		}
		&__color {
			order: 2;

			button {
				padding-top: 5px;
				padding-bottom: 5px;
				border-width: 1px;
			}
			ul {
				top: 100%;
			}
			li {
				font-size: 12px;
			}
		}
		&__price {
			grid-area: price;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			
			padding: 5px 10px;
			background-color: rgba($black, 0.05);
			line-height: 1;
		}
		&__quantity {
			grid-area: quantity;

			display: flex;
			justify-content: flex-start;
			align-items: center;
			
			padding: 5px 10px;
			background-color: rgba($black, 0.05);
			font-size: 1.3em;
		}
		&__button {
			grid-area: button;
			
			button {
				display: block;
				width: 100%;
				padding: 5px 10px;
				border: 1px solid $black;
				text-align: center;
				
				&:hover {
					background-color: rgba($black, 0.05);
				}
				&:active {
					padding: 5px 9px 5px 11px;
				}

				&[disabled] {
					&:hover {
						background: none;
					}
					&:active {
						padding: 5px 10px;
					}
				}
				
				> span {
					display: inline;
				}
				> i {
					display: none;
				}
			}
		}
		.color-alert {
			top: -40px;
		}
	}
}
@include media-breakpoint-down(sm) {
	.products-grid {
		padding-top: 0;
		padding-bottom: 0;

		&__element {
			font-size: 0.7rem;

			&--group,
			&--unavailable {
				.products-grid {
					&__actions {
						min-height: 85px;
					}
				}
			}
		}

		&__image {
			img {
				max-width: 100px;
			}
		}
		&__name {
			font-size: 1.05rem;
		}
		&__description {
			font-size: 0.8rem;
		}
		&__color {
			li {
				font-size: 10px;
			}
		}
	}
	*:lang('id-ID')
		{
			.products-grid__price {
				font-size: 0.85rem;
			}
			.products-grid__quantity{
				font-size: 1.1em;
			}
		}
	.products-grid__price {
		min-height: 50px;
		padding:5px 5px;
	}
	.products-grid__more{
		padding:0;
	}
	.products-grid__quantity{
		padding:5px 5px;
	}
}
@include media-breakpoint-down(_sm, (_sm: 350px)) {
	.products-grid {
		position: relative;
		z-index: 2;
		padding-top: 10px;
		padding-bottom: 10px;
		
		&__container {
			position: relative;
			z-index: 1;
		}
		&__slider-nav {
			display: block;
		}
		&__nav-element {
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 2;
			width: calc(50vw - 115px);
			cursor: pointer;

			&--left {
				left: 0;
			}
			&--right {
				right: 0;
			}

			&:active {
				.products-grid__nav-arrow {
					&--left {
						&::before,
						&::after {
							right: 2px;
							left: -2px;
						}
					}
					&--right {
						&::before,
						&::after {
							right: -2px;
							left:   2px;
						}
					}
				}
			}
			&:hover {
				.products-grid__nav-arrow {
					transform: scale(1.2);
				}
			}
		}
		&__nav-arrow {
			position: absolute;
			top: calc(50% - 1.55em);
			left: calc(50% - 1em);
			width: 2em;
			height: 3.1em;
			border: none;
			background: none;
			transition: ease 300ms;

			&::before,
			&::after {
				content: '';
				position: absolute;
				right: 0;
				left: 0;
				display: block;
				height: 4px;
				background-color: $black;
			}
			&::before {
				transform: rotateZ(60deg);
			}
			&::after {
				transform: rotateZ(-60deg);
			}
			
			&--left {
				&,
				&::before,
				&::after {
					transform-origin: left;
				}
				&::before {
					top: calc(50% - 3px);
				}
				&::after {
					top: calc(50% - 1px);
				}
			}
			&--right {
				&,
				&::before,
				&::after {
					transform-origin: right;
				}
				&::before {
					top: calc(50% - 1px);
				}
				&::after {
					top: calc(50% - 3px);
				}
			}
		}
		&__wrapper {
			flex-wrap: nowrap;
			
			.slick-track {
				display: flex;
				flex-wrap: nowrap;
				gap: 5px;
			}
		}
		&__element {
			flex: 0 0 220px;
			z-index: 1;
			padding: 5px;
			background-color: lighten($lightgray, 5%);
			opacity: 0.35;
			transition: ease 500ms;
			transition-property: opacity;
			user-select: none;

			&--group,
			&--unavailable {
				.products-grid {
					&__actions {
						min-height: 29px;
					}
				}
			}

			&--active {
				opacity: 1;
			}
		}
		&__colors {
			ul {
				// top: 100%;
				background-color: lighten($lightgray, 5%);
			}
		}
	}
}

.variant-color.color--active {
	&:before {
		top: -3px;
		left: -3px;
		bottom: -3px;
		right: -3px;
		border: 1px black solid;
	}
}

@media (min-width: 992px) {
	.button-block {
		width: 125px;
	}
	.products-grid__element  {
		display: flex;
		flex-direction: column;
	}
/* 	.products-grid__actions {
		height: 100%;
	} */
}

@media screen and (max-width: 575.98px) {
	.change_color {
		font-size: 1rem;
	}
	
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
	.button-block {
		display: flex;
		gap: 10px;
		align-items: center;
	}
}