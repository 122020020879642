.flag {
	width: 32px;
	height: 32px;
	background-image: url('#{$assets_path}img/flags.avif');
	background-repeat: no-repeat;
	background-position: 0 0;
	border: none;
	font-size: 0;
	line-height: 0;
	&--none {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		font-family: 'Nanocons' !important;
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&::before {
			content: "\e9001";
			font-size: 28px;
		}
	}
	&--ad { background-position: -32px    0    }
	&--ae { background-position: -64px    0    }
	&--af { background-position: -96px    0    }
	&--ag { background-position: -128px   0    }
	&--ai { background-position: -160px   0    }
	&--al { background-position: -192px   0    }
	&--am { background-position: -224px   0    }
	&--an { background-position: -256px   0    }
	&--ao { background-position: -288px   0    }
	&--ar { background-position: -320px   0    }
	&--as { background-position: -352px   0    }
	&--at { background-position: -384px   0    }
	&--au { background-position: -416px   0    }
	&--aw { background-position: -448px   0    }
	&--az { background-position:  0      -32px }
	&--ba { background-position: -32px   -32px }
	&--bb { background-position: -64px   -32px }
	&--bd { background-position: -96px   -32px }
	&--be { background-position: -128px  -32px }
	&--bf { background-position: -160px  -32px }
	&--bg { background-position: -192px  -32px }
	&--bh { background-position: -224px  -32px }
	&--bi { background-position: -256px  -32px }
	&--bj { background-position: -288px  -32px }
	&--bm { background-position: -320px  -32px }
	&--bn { background-position: -352px  -32px }
	&--bo { background-position: -384px  -32px }
	&--br { background-position: -416px  -32px }
	&--bs { background-position: -448px  -32px }
	&--bt { background-position:  0      -64px }
	&--bw { background-position: -32px   -64px }
	&--by { background-position: -64px   -64px }
	&--bz { background-position: -96px   -64px }
	&--ca { background-position: -128px  -64px }
	&--cd { background-position: -160px  -64px }
	&--cf { background-position: -192px  -64px }
	&--cg { background-position: -224px  -64px }
	&--ch { background-position: -256px  -64px }
	&--ci { background-position: -288px  -64px }
	&--ck { background-position: -320px  -64px }
	&--cl { background-position: -352px  -64px }
	&--cm { background-position: -384px  -64px }
	&--cn { background-position: -416px  -64px }
	&--co { background-position: -448px  -64px }
	&--cr { background-position:  0      -96px }
	&--cu { background-position: -32px   -96px }
	&--cv { background-position: -64px   -96px }
	&--cy { background-position: -96px   -96px }
	&--cz { background-position: -128px  -96px }
	&--de { background-position: -160px  -96px }
	&--dj { background-position: -192px  -96px }
	&--dk { background-position: -224px  -96px }
	&--dm { background-position: -256px  -96px }
	&--do { background-position: -288px  -96px }
	&--dz { background-position: -320px  -96px }
	&--ec { background-position: -352px  -96px }
	&--ee { background-position: -384px  -96px }
	&--eg { background-position: -416px  -96px }
	&--eh { background-position: -448px  -96px }
	&--er { background-position:  0     -128px }
	&--es { background-position: -32px  -128px }
	&--et { background-position: -64px  -128px }
	&--fi { background-position: -96px  -128px }
	&--fj { background-position: -128px -128px }
	&--fm { background-position: -160px -128px }
	&--fo { background-position: -192px -128px }
	&--fr { background-position: -224px -128px }
	&--ga { background-position: -256px -128px }
	&--gb { background-position: -288px -128px }
	&--gd { background-position: -320px -128px }
	&--ge { background-position: -352px -128px }
	&--gg { background-position: -384px -128px }
	&--gh { background-position: -416px -128px }
	&--gi { background-position: -448px -128px }
	&--gl { background-position:  0     -160px }
	&--gm { background-position: -32px  -160px }
	&--gn { background-position: -64px  -160px }
	&--gp { background-position: -96px  -160px }
	&--gq { background-position: -128px -160px }
	&--gr { background-position: -160px -160px }
	&--gt { background-position: -192px -160px }
	&--gu { background-position: -224px -160px }
	&--gw { background-position: -256px -160px }
	&--gy { background-position: -288px -160px }
	&--hk { background-position: -320px -160px }
	&--hn { background-position: -352px -160px }
	&--hr { background-position: -384px -160px }
	&--ht { background-position: -416px -160px }
	&--hu { background-position: -448px -160px }
	&--id { background-position:  0     -192px }
	&--ie { background-position: -32px  -192px }
	&--il { background-position: -64px  -192px }
	&--im { background-position: -96px  -192px }
	&--in { background-position: -128px -192px }
	&--iq { background-position: -160px -192px }
	&--ir { background-position: -192px -192px }
	&--is { background-position: -224px -192px }
	&--it { background-position: -256px -192px }
	&--je { background-position: -288px -192px }
	&--jm { background-position: -320px -192px }
	&--jo { background-position: -352px -192px }
	&--jp { background-position: -384px -192px }
	&--ke { background-position: -416px -192px }
	&--kg { background-position: -448px -192px }
	&--kh { background-position:  0     -224px }
	&--ki { background-position: -32px  -224px }
	&--km { background-position: -64px  -224px }
	&--kn { background-position: -96px  -224px }
	&--kp { background-position: -128px -224px }
	&--kr { background-position: -160px -224px }
	&--kw { background-position: -192px -224px }
	&--ky { background-position: -224px -224px }
	&--kz { background-position: -256px -224px }
	&--la { background-position: -288px -224px }
	&--lb { background-position: -320px -224px }
	&--lc { background-position: -352px -224px }
	&--li { background-position: -384px -224px }
	&--lk { background-position: -416px -224px }
	&--lr { background-position: -448px -224px }
	&--ls { background-position:  0     -256px }
	&--lt { background-position: -32px  -256px }
	&--lu { background-position: -64px  -256px }
	&--lv { background-position: -96px  -256px }
	&--ly { background-position: -128px -256px }
	&--ma { background-position: -160px -256px }
	&--mc { background-position: -192px -256px }
	&--md { background-position: -224px -256px }
	&--me { background-position: -256px -256px }
	&--mg { background-position: -288px -256px }
	&--mh { background-position: -320px -256px }
	&--mk { background-position: -352px -256px }
	&--ml { background-position: -384px -256px }
	&--mm { background-position: -416px -256px }
	&--mn { background-position: -448px -256px }
	&--mo { background-position:  0     -288px }
	&--mq { background-position: -32px  -288px }
	&--mr { background-position: -64px  -288px }
	&--ms { background-position: -96px  -288px }
	&--mt { background-position: -128px -288px }
	&--mu { background-position: -160px -288px }
	&--mv { background-position: -192px -288px }
	&--mw { background-position: -224px -288px }
	&--mx { background-position: -256px -288px }
	&--my { background-position: -288px -288px }
	&--mz { background-position: -320px -288px }
	&--na { background-position: -352px -288px }
	&--nc { background-position: -384px -288px }
	&--ne { background-position: -416px -288px }
	&--ng { background-position: -448px -288px }
	&--ni { background-position:  0     -320px }
	&--nl { background-position: -32px  -320px }
	&--no { background-position: -64px  -320px }
	&--np { background-position: -96px  -320px }
	&--nr { background-position: -128px -320px }
	&--nz { background-position: -160px -320px }
	&--om { background-position: -192px -320px }
	&--pa { background-position: -224px -320px }
	&--pe { background-position: -256px -320px }
	&--pf { background-position: -288px -320px }
	&--pg { background-position: -320px -320px }
	&--ph { background-position: -352px -320px }
	&--pk { background-position: -384px -320px }
	&--pl { background-position: -416px -320px }
	&--pr { background-position: -448px -320px }
	&--ps { background-position:  0     -352px }
	&--pt { background-position: -32px  -352px }
	&--pw { background-position: -64px  -352px }
	&--py { background-position: -96px  -352px }
	&--qa { background-position: -128px -352px }
	&--re { background-position: -160px -352px }
	&--ro { background-position: -192px -352px }
	&--rs { background-position: -224px -352px }
	&--ru { background-position: -256px -352px }
	&--rw { background-position: -288px -352px }
	&--sa { background-position: -320px -352px }
	&--sb { background-position: -352px -352px }
	&--sc { background-position: -384px -352px }
	&--sd { background-position: -416px -352px }
	&--se { background-position: -448px -352px }
	&--sg { background-position:  0     -384px }
	&--si { background-position: -32px  -384px }
	&--sk { background-position: -64px  -384px }
	&--sl { background-position: -96px  -384px }
	&--sm { background-position: -128px -384px }
	&--sn { background-position: -160px -384px }
	&--so { background-position: -192px -384px }
	&--sr { background-position: -224px -384px }
	&--st { background-position: -256px -384px }
	&--sv { background-position: -288px -384px }
	&--sy { background-position: -320px -384px }
	&--sz { background-position: -352px -384px }
	&--tc { background-position: -384px -384px }
	&--td { background-position: -416px -384px }
	&--tg { background-position: -448px -384px }
	&--th { background-position:  0     -416px }
	&--tj { background-position: -32px  -416px }
	&--tl { background-position: -64px  -416px }
	&--tm { background-position: -96px  -416px }
	&--tn { background-position: -128px -416px }
	&--to { background-position: -160px -416px }
	&--tr { background-position: -192px -416px }
	&--tt { background-position: -224px -416px }
	&--tv { background-position: -256px -416px }
	&--tw { background-position: -288px -416px }
	&--tz { background-position: -320px -416px }
	&--ua { background-position: -352px -416px }
	&--ug { background-position: -384px -416px }
	&--us { background-position: -416px -416px }
	&--uy { background-position: -448px -416px }
	&--uz { background-position:  0     -448px }
	&--va { background-position: -32px  -448px }
	&--vc { background-position: -64px  -448px }
	&--ve { background-position: -96px  -448px }
	&--vg { background-position: -128px -448px }
	&--vi { background-position: -160px -448px }
	&--vn { background-position: -192px -448px }
	&--vu { background-position: -224px -448px }
	&--ws { background-position: -256px -448px }
	&--ye { background-position: -288px -448px }
	&--za { background-position: -320px -448px }
	&--zm { background-position: -352px -448px }
	&--zw { background-position: -384px -448px }
}

.NoSupportAvif {
	.flag {
		background-image: url('#{$assets_path}img/flags.webp');
	}
}

.NoSupportWebp {
	.flag {
		background-image: url('#{$assets_path}img/flags.png');
	}
}