.social-media + .footer {
	margin-top: 0;
}
.footer {
	margin-top: auto;
	padding: 40px 0;
	background-color: $semiwhite;

	&__wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	&__element {
		flex-shrink: 0;
		flex-grow: 0;
		margin: 5px 0;
		padding: 0 20px;
		border-right: 1px solid $gray;
		line-height: 1;

		&:last-child {
			border-right: none;
		}
		
		a {
			&, &:link, &:visited {
				color: lighten($gray, 20%);
				font-size: 1.2rem;
				line-height: 1;
				font-weight: 300;
				text-decoration: none;
			}
			&:hover {
				color: darken($gray, 20%);
				text-decoration: underline;
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.footer {
		&__element {
			padding: 0 15px;
		}
	}
}
@include media-breakpoint-down(lg) {
	.footer {
		padding: 20px 0;
		
		&__element {
			padding: 0 10px;

			a {
				&, &:link, &:visited {
					font-size: 1rem;
				}
			}
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.footer {
		padding: 10px 0;
	}
}