.variant-color {
	--variant-color: transparent;

	position: relative;
	z-index: 1;
	// display: block;
	display: flex;
	width: 1em;
	height: 1em;
	border: 1px solid rgba($black, 0.3);
	cursor: pointer;

	// & > i {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	display: block;
	// 	background-color: var(--variant-color);
	// 	overflow: hidden;

	// 	&::before,
	// 	&::after {
	// 		content: '';
	// 		position: absolute;
	// 		top:    0;
	// 		right:  0;
	// 		bottom: 0;
	// 		left:   0;
	// 		z-index: -1;
	// 		transform: skewX(45deg);
	// 		transform-origin: bottom;
	// 	}
	// 	&::before {
	// 		background-color: rgba($black, 0.07);
	// 	}
	// 	&::after {
	// 		background-color: var(--variant-color);
	// 	}
	// }

	&::before {
		content: '';
		position: absolute;
		top:    0;
		right:  0;
		bottom: 0;
		left:   0;
		background-color: transparent;
	}
	i {
		background-color: var(--variant-color);
		flex: 1 1 0;
		display: block;
	}
	&--unavailable {
		cursor: default;

		& > i {
			opacity: 0.5;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			top:    calc(50% - 0.5px);
			right:  10%;
			bottom: calc(50% - 0.5px);
			left:   10%;
			z-index: 3;
			display: block;
			background-color: rgba($black, 0.5);
		}
		&::before {
			transform: rotateZ(45deg);
		}
		&::after {
			transform: rotateZ(-45deg);
		}
	}

	&--active {
		&::before {
			top:    -0.12em;
			right:  -0.12em;
			bottom: -0.12em;
			left:   -0.12em;
			border: 1px solid rgba($black, 0.75);
		}
	}
}
.variant-color--white {
	&.active {
		&:before {
			border: 1px solid rgba($white, 0.75);
		}
	}
	&.variant-color {
		border: 1px solid rgba($white, 0.3);

		// & > i {
		// 	&::before {
		// 		background-color: rgba($white, 0.07);
		// 	}
		// }

		&--unavailable {
			&::before,
			&::after {
				background-color: rgba($white, 0.5);
			}
		}
		&--active {
			&::before {
				border: 1px solid rgba($white, 0.75);
			}
		}
	}
}

.color-alert {
	position: absolute;
	top: -50px;
	right: 0;
	padding: 7px 14px;
	border: 1px $black solid;
	background-color: $white;
	display: none;
	color: $black;
	&:after {
		content: '';
		width: 12px;
		height: 12px;
		bottom: -7px;
		left: 18px;
		display: block;
		position: absolute;
		background-color: $white;
		border-bottom: 1px $black solid;
		border-right: 1px $black solid;
		transform: rotate(45deg);
	}
}
.esk-alert {
	top: 30px;
}