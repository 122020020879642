$assets_path: '../';

@import "includes/bootstrapCore";
// @import "includes/breakpoints";
@import "includes/flags";
@import "includes/fonts";
@import "includes/animations";

@import "mixins/functions";
@import "mixins/colors";
@import "mixins/layersIndexes";
@import "mixins/snippets";

@import 'components/modal';
@import "components/nav";
@import "components/qnt-counter";
@import "components/header";
@import "components/footer";
@import "components/cookies-notify";

html, body {
	height: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	-webkit-text-size-adjust: none;
}

strong {
	font-weight: 700;
}

.strong {
	font-weight: 700;
	display: inline;
}

.h {
	font-size: unset;
	font-weight: unset;
	display: inline; 
}

select {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	background: transparent url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Warstwa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 10' style='enable-background:new 0 0 20 10;' xml:space='preserve'%3e%3cg id='layer1' transform='translate(0,-294.35415)'%3e%3cpath id='rect4522' d='M18.7,294.4L10,303l-8.7-8.7l-1.3,0l10,10l10-10L18.7,294.4z'/%3e%3c/g%3e%3c/svg%3e") right center/1em auto no-repeat;
}
select::-ms-expand {
	display: none;
}
@media screen and (min-width:0\0) {
	select {
		background-image: none\9;
		padding: 5px\9;
	}
}

body > div:first-of-type {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow: hidden;
}

.new-price {
	white-space: nowrap;
	display: inline;
}
.old-price {
	font-size: 0.7em;
	line-height: 1;
	margin-right:1px;
	white-space: nowrap;
	// text-decoration: line-through;
	// text-decoration-color: $red;
	opacity: 1;
	color: rgba($black, 0.5);
	position: relative;
	margin-right: 0.3em;
	&:after {
		content: '';
		border-bottom: 2px solid $red;
		position: absolute;
		width: 110%;
		height: 50%;
		transform: rotate(-10deg) translateY(15%);
		left: -5%;
	}
}

.nav-cart__cell {
	.old-price {
		&:after {
			transform: rotate(-15deg) translateY(30%);
		}
	}
}

.products-grid__price {
	.old-price {
		&:after {
			transform: rotate(-15deg) translateY(40%);
		}
	}
}

.module {
	.add-to-cart__price {
		.old-price {
			&:after {
				transform: rotate(-10deg) translateY(50%);
			}
		}
	}
}

.add-to-cart--white {
	.old-price {
		color: rgba($white, 0.5);
	}
}

.old-price {
	display: none;
}

.blackfriday {
	.header {
		padding-top:156px;
	}
	.old-price {
		display: initial;
	}
}
.blackfriday {
	.products-grid__actions {
		gap: calc(var(--bs-gutter-x)* 0.2);
		min-height:71px;
	}
}

%title {
	margin-bottom: 20px;
	font-size: 2.5rem;
	line-height: 1.2;
	font-weight: 100;
	text-transform: uppercase;

	strong {
		font-weight: 900;
	}
}
@include media-breakpoint-down(lg) {
	%title {
		font-size: 2.2rem;
	}
}
@include media-breakpoint-down(md) {
	%title {
		font-size: 2rem;
	}
}
@include media-breakpoint-down(sm) {
	%title {
		font-size: 1.75rem;
	}
}
@include media-breakpoint-down(_sm, (_sm: 368px)) {
	%title {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 575.98px) {
	.blackfriday .header {
		padding-top: 120px;
	}
}

