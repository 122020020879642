.cookies {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $cookies-notify-index;
	padding: 10px 0;
	border-top: 1px solid $gray;
	background-color: rgba($white, 0.9);
	&-popup {
		display: grid;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9020;
    place-items: center;
    padding: 10px;
    overflow: auto;
	}
	&__wrapper {
		align-items: center;
	}
	&__content {
		font-size: 0.9rem;
		line-height: 1.2;
	}
	&__close {
		flex: none;
		position: absolute;
		right: 0;
		display: flex;
		width: max-content;

		button {
			&:focus,
			&:focus-visible {
				outline: none;
				box-shadow: none;
			}
		}
	}
	p {
		margin-bottom: 0;
	}
}
@include media-breakpoint-down(lg) {
	.cookies {
		padding: 5px 0;

		&__content {
			font-size: 0.8rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.cookies {
		&__close {
			position: static;
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.cookies {
		&__content {
			padding-right: 0;
		}
		&__close {
			padding-left: 0;
		}
	}
}

/* cookies notice --------------------------------------------------------------------- */

.new-cookies-notices {
	position: relative;
	box-sizing: border-box;
	z-index: 10;
	left: 0;
	top: 0;
	width: calc(100% - 15px);
	max-width: 550px;
	height: 100%;
	max-height: calc(100vh - 90px);
	min-height: 200px;
	padding: 10px;
	color: $black;
	display: grid;
	grid-template-columns: 100%;
	place-content: center;
	place-items: center;
	transition: opacity 0.3s ease;
	&-popup {
		display: grid;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9020;
		place-items: center;
		padding: 10px;
		overflow: auto;
			&.is-closed {
		opacity: 0;
		pointer-events: none;
	}
	}
	&:not(.is-open) {
		height: unset;
		background: rgba(255, 255, 255, 0.96);
		border-top: 1px solid #ccc;
		.new-cookies-notices__container {
			position: static;
			background: transparent;
			padding: 0;
		}
		.new-cookies-notices__form {
			display: none;
		}
	}
	&.is-closed {
		opacity: 0;
		pointer-events: none;
	}
	&__container {
		width: 635px;
		max-width: 100%;
		padding: 10px 20px;
		position: relative;
		background-color: $white;
		font-family: "Roboto", sans-serif;
	}
	&__close {
		display: none;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		background: transparent;
		border: none;
		box-shadow: none;
		&.show {
			display: block; 
		}
	}
	&__heading {
		font-weight: 700;
		font-size: 1.3em;
		line-height: 1.5em;
		text-align: left;
		a {
			color: #222;
			text-decoration: underline;
		}
	}
	&__message {
		display: block;
		margin-bottom: 10px;
		line-height: 1.7em;
		font-size: max(9px, 0.9em);
	}
	&__form,
	&__options {
		display: grid;
		gap: 10px;
	}
	&__form {
		grid-template-columns: 100%;
	}
	&__options {
		grid-template-columns: repeat(2, minmax(1px, 1fr));
		margin-top: 10px;
	}
	&__option {
		padding: 8px 10px;
		border: 1px solid $lightgray;
		position: relative;
		cursor: pointer;
		user-select: none;
		top: unset;
		left: unset;
		font-size: 12px;
		line-height: inherit;
		color: inherit;
		font-weight: 400;
		input {
			z-index: 0;
			padding: 0;
			width: auto;
			margin: 0 5px 0 0;
			font-size: inherit;
			line-height: inherit;
			font-weight: inherit;
			vertical-align: middle;
		}
	}
	&__buttons {
		margin-top: 10px;
		display:block;
		&.hidden {
			display: none;
		}
	}
	&__button {
		display: block;
		max-width: 100%;
		width: 100%;
		padding: 7px 15px;
		border: 1px solid #ddd;
		border-radius: 0;
		cursor: pointer;
		text-decoration: none;
		background-color: $white;
		color: $darkgray;
		margin-bottom: 10px;
		font-size: 0.9rem;
		text-align: center;
		transition: all 0.15s ease-in;
		box-sizing: border-box;
		line-height: normal;
		-webkit-appearance: none;
		&__button:visited {
			color: $darkgray;
		}
		&.btn-lg {
			padding: 10px 15px;
			margin: 0;
			font-size: 0.7rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&--accept-all {
			border-color: $darkgray;
			background: $darkgray;
			color: $white;
		}
		&--accept-required {
			border-color: $lightgray;
			color: $darkgray;
		}
		&:hover,
		&:focus,
		&:active {
			color: $white;
			background-color: $orange;
			border-color: $orange;
			text-decoration: none;
		}
	}
}

@media (min-width: 768px) {
	.new-cookies-notices {
		&__container {
			max-width: calc(100% - 40px);
		}
		&__form {
			grid-template-columns: repeat(3, minmax(1px, 1fr));
		}
		&__options {
			grid-template-columns: repeat(3, minmax(1px, 1fr));
			grid-column-end: span 3;
		}
	}
}

@media (min-width: 460px) {
	.new-cookies-notices {
		&__buttons {
			text-align: center;
			margin: 10px 0 5px;
		}
		&__button {
			max-width: none;
			min-width: 130px;
			width: auto;
			display: inline-block;
			margin: 0 10px 0 0;
			padding: 4px 10px 5px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media (min-width: 960px) {
	.new-cookies-notices {
		&__message,
		&__buttons {
			display: block;
			vertical-align: middle;
		}
		&__buttons {
			margin-left: 15px;
		}
	}
}
@media (min-width: 1300px) {
	.new-cookies-notices:not(.is-open) .new-cookies-notices__container {
		width: 1100px;
	}
}
@media (min-width: 1500px) {
	.new-cookies-notices:not(.is-open) .new-cookies-notices__container {
		width: 1300px;
	}
}

@media (max-width:578px) {
	.cookies-popup{
		padding:0;
	}
	.new-cookies-notices {
		max-width:calc(100vw);
	}
}