.modal {
	display: block;

	&-backdrop {
		z-index: -1;
		opacity: 0.5;
	}
	&-container {
		max-width: calc(1320px - 1.5rem);
	}
	&-content {
		border-radius: 0;
	}
	&-title {
		font-size: 1.35rem;
		line-height: 1;
		font-weight: 300;
		text-transform: uppercase;
	}

	&__button {
		margin: 0 0.25rem;
		padding: 5px 10px;
		border: none;
		background-color: $darkgray;
		color: $white;
	}

	.btn-close {
		opacity: 1;
		&:focus,
		&:focus-visible {
			outline: none;
			box-shadow: none;
		}
	}
	&-tab {
		&.current {
			background: #000;
    		color: #fff;
		}
		text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 3% 6%;
    margin-top: 1%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    border-right: 2px solid #e5e5e5;
    border-bottom: 2px solid #e5e5e5;
    cursor: pointer;
		&-container {
			display: grid;
    		grid-template-columns: repeat(3, 1fr);
		}
	}
}
@include media-breakpoint-down(xxl) {
	.modal {
		&-container {
			max-width: calc(1140px - 1.5rem);
		}
	}
}
@include media-breakpoint-down(xl) {
	.modal {
		&-container {
			max-width: calc(960px - 1.5rem);
		}
	}
}
@include media-breakpoint-down(lg) {
	.modal {
		&-container {
			max-width: calc(720px - 1.5rem);
		}
	}
}
@include media-breakpoint-down(md) {
	.modal {
		&-container {
			max-width: calc(540px - 1.5rem);
		}
		&-title {
			font-size: 1.1rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.modal {
		&-dialog-centered {
			min-height: calc(100% - 1.5rem);
		}
		&-dialog-scrollable {
			height: calc(100% - 1.5rem);
		}
		&-container {
			// margin: 0.75rem;
			margin: 2.5rem 0.75rem;
			max-width: 100%;
		}
		&-body {
			padding: 0.5rem;
		}
	}
}

@include media-breakpoint-up(sm) {
	.modal {
		&-dialog {
			margin: 4.5rem auto;
		}
	}
}