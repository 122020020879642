.brand-banner {
	position: relative;

	&__image {
		text-align: center;

		img {
			width: 1920px;
		}
	}
	&__wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	&__title {
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;
		font-size: 2rem;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 0.2em;
		text-transform: uppercase;
	}
}
@include media-breakpoint-down(xl) {
	.brand-banner {
		&__title {
			font-size: 1.75rem;
		}
	}
}
@include media-breakpoint-down(lg) {
	.brand-banner {
		&__title {
			font-size: 1.5rem;
		}
	}
}
@include media-breakpoint-down(md) {
	.brand-banner {
		&__title {
			font-size: 1.25rem;
			letter-spacing: 0.1em;
		}
	}
}
@include media-breakpoint-down(sm) {
	.brand-banner {
		&__title {
			font-size: 1rem;
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.brand-banner {
		&__title {
			font-size: 0.9rem;
		}
	}
}

@include media-breakpoint-up(sm) {
	.brand-banner {
		&__wrapper {
			gap: 10px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.brand-banner {
		&__wrapper {
			gap: 15px;
		}
	}
}

@include media-breakpoint-up(xxl) {
	.brand-banner {
		&__wrapper {
			gap: 25px;
		}
	}
}