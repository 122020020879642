.nav {
	--nav-color: #{$nav-color};
	--nav-padding: 15px;
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: $nav-index;
	padding: var(--nav-padding) 0;
	background-color: $nav-bg;
	color: $nav-color;
	transition: 300ms ease-out;
	transition-property: padding-top, padding-bottom;
	&.mb{
		padding-bottom:0;
	}
	&__products button,
	&__social a,
	&__language button,
	&__flag button,
	&__cart button,
	&__cart-badge {
		transition: 300ms;
		&:hover {
			--nav-color: #{$white};
		}
	}
	
	&__row {
		position: relative;
		align-items: flex-end;
		flex-wrap: nowrap;
	}
	&__hamburger {
		box-sizing: content-box;
		align-self: center;
		flex: 0 0 34px;
	}
	&__logo {
		box-sizing: content-box;
		flex: 0 0 250px;
		
		img {
			width: auto;
			height: 56px;
			transition: 300ms ease-out;
		}
	}
	&__products {
		position: relative;

		button {
			position: relative;
			display: block;
			margin: 0 auto;
			width: max-content;
			padding: 0;
			border: none;
			background: none;
			color: var(--nav-color);
			font-size: 24px;
			line-height: 32px;
			font-weight: 900;
			letter-spacing: 2px;
			text-transform: uppercase;

			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
				bottom: 0;
				height: 1px;
				width: 0;
				background-color: var(--nav-color);
				transition: 500ms;
			}
			&::before {
				right: 50%;
			}
			&::after {
				left: 50%;
			}

			&:hover {
				&::before,
				&::after {
					width: 50%;
					transition: 200ms;
				}
			}
		}
	}
	&__blog {
		position: relative;
		display: block;
		margin: 0 auto;
		width: max-content;
		border: none;
		background: none;
		color: var(--nav-color);
		font-size: 24px;
		line-height: 32px;
		font-weight: 900;
		letter-spacing: 2px;
		text-transform: uppercase;
		flex: 0 0;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 1px;
			background-color: var(--nav-color);
		}
		a {
			text-decoration: none;
			color: inherit;
			transition: 0.3s;
			&:hover {
				color: #fff;
			}
		}
	}
	&__social {
		flex: 0 0;

		ul {
			display: flex;
			margin: 0;
			padding: 0 30px 1px 0;
			list-style-type: none;
		}
		li {
			position: relative;
			padding: 0 0.75rem;

			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;

				&::after {
					display: none;
				}
			}
			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				display: block;
				width: 1px;
				background-color: $nav-color;
			}
		}
		a {
			color: var(--nav-color);
			font-size: 32px;
			line-height: 1;
			text-decoration: none;
		}
	}
	&__language {
		flex: 0 0;
		padding-right: 0;

		button {
			display: flex;
			align-items: center;
			height: 32px;
			padding: 0;
			border: none;
			background: none;
			color: var(--nav-color);
			font-size: 16px;
			line-height: 1;
			text-transform: uppercase;
			font-weight: 400;

			> * {
				display: block;
				flex-shrink: 0;
				flex-grow: 0;
				flex-basis: 0;
			}
		}
		i {
			align-self: flex-start;
			margin-left: 10px;
			font-size: 26px;
			line-height: 1;
		}
	}
	&__flag {
		flex: 0 0;
		button {
			background-color: transparent;
			padding: 0;
			color: var(--nav-color);
		}
	}
	&__cart {
		position: relative;
		display: flex;
		flex: 0 0;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			display: block;
			width: 1px;
			background-color: $nav-color;
		}
		button {
			position: relative;
			padding: 0;
			border: none;
			background: none;
			font-size: 32px;
			line-height: 1;
			color: var(--nav-color);
			transition: 300ms;
		}
		i {
			display: block;
		}
	}
	&__cart-badge {
		$dimension: 18px;

		position: absolute;
		top: calc(16% - #{math.div($dimension, 2)});
		right: calc(-1% - #{math.div($dimension, 2)});
		min-width: $dimension;
		height: $dimension;
		border-radius: 50%;
		background-color: var(--nav-color);
		text-align: center;
		color: $nav-bg;
		font-size: 14px;
		line-height: 18px;
		font-weight: 700;
	}
	.notification_promo{
		background-color: #FF7E00;
        padding:5px;
		margin-top:15px;
		.notification_text {
			text-align: center;
			span {
				display: block;
				font-size:20px;
				font-weight:400;
				padding:5px 0;
				&:first-of-type{
					font-size:28px;
					font-weight:600;
				}
			}
			&_inner {
				font-size:17px!important;
				font-weight:400!important; 
				color:white;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.nav--scrolled {
		--nav-padding: 10px;
		
		.nav {
			&__logo {
				img {
					height: 38px;
				}
			}
		}
	}
}
@include media-breakpoint-down(xxl) {
	.nav {
		&__products {
			button {
				font-size: 20px;
			}
		}
		&__social {
			ul {
				padding: 0 0 1px 0;
			}
		}
	}
}
@include media-breakpoint-down(xl) {
	.nav {
		&__products {
			position: static;
		}
	}
}
@include media-breakpoint-down(lg) {
	.nav {
		&__logo {
			flex-grow: 1;
			flex-basis: 201px;

			img {
				width: auto;
				height: 45px;
			}
		}
		&__products {
			button {
				font-size: 18px;
			}
		}
		&__language {
			i {
				margin-left: 0;
			}
			span {
				display: none;
			}
		}
	}
}
@include media-breakpoint-down(md) {
	.nav {
		--nav-padding: 10px;

		&__logo {
			flex-basis: 179px;

			img {
				height: 40px;
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.nav {
		--nav-padding: 7px;

		&__row {
			.col {
				&:not(.nav__hamburger):not(.nav__flag):not(.nav__cart):not(.nav__blog) {
					padding-right: 0;
				}
			}
		}
		&__hamburger {
			flex-basis: 26px;
		}
		&__logo {
			flex-basis: 125px;
			text-align: left;

			img {
				height: 28px;
			}
		}
		&__cart {
			button {
				padding-bottom: 2px;
				font-size: 26px;
			}
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 368px)) {
	.nav {
		&__row {
			.col {
				&.nav__hamburger {
					padding-right: calc(var(--bs-gutter-x) * .2);
				}
			}
		}
		&__logo {
			flex-basis: 108px;
			align-self: center;

			img {
				height: 24px;
			}
		}
	}
}

.hamburger {
	position: relative;
	display: block;
	width: 34px;
	height: 22px;
	padding: 0;
	border: none;
	background: none;

	&__line {
		position: absolute;
		right: 0;
		left: 0;
		display: block;
		height: 2px;
		background-color: $nav-color;
		border-radius: 1px;
	}
}
.hamburger--open {
	.hamburger__line {
		&:nth-child(1) {
			top: 0;
			right: 8px;
		}
		&:nth-child(2) {
			top: calc(50% - 1px);
		}
		&:nth-child(3) {
			right: 4px;
			bottom: 0;
		}
	}
}
.hamburger--close {
	.hamburger__line {
		&:nth-child(1) {
			top: calc(50% - 1px);
			transform: rotateZ(45deg);
		}
		&:nth-child(2) {
			top: calc(50% - 1px);
			transform: rotateZ(-45deg);
		}
	}
}
@include media-breakpoint-down(sm) {
	.hamburger {
		width: 26px;
		height: 18px;
	}
	.hamburger--open {
		.hamburger__line {
			&:nth-child(1) {
				right: 6px;
			}
			&:nth-child(3) {
				right: 3px;
			}
		}
	}
}

$menu-columns: 3;
.menu {
	position: absolute;
	top: 100%;
	right: 0;
	bottom: calc(-100vh + 100%);
	left: 0;
	z-index: 10;

	&__container {
		position: relative;
		height: 100%;
	}
	&__wrapper {
		background-color: $menu-bg;
	}
	&__title {
		margin-bottom: 30px;
		text-align: center;
		color: darken($menu-border, 20%);
		font-size: 28px;
		line-height: 1;
		font-weight: 900;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	&__products {
		display: grid;
		grid-template-columns: repeat(#{$menu-columns}, 1fr);
	}
	&-element {
		&__title {
		text-align: center;
		text-transform: uppercase;
		font-size: 1.5rem;
		padding: 3% 6%;
		margin-top: 1%;
		display: flex;
		justify-content: center;
		vertical-align: bottom;
		border-right: 2px solid #e5e5e5;
		border-bottom: 2px solid #e5e5e5;
		cursor:pointer;
			&:nth-child(#{$menu-columns}n) {
				border-right: none;
			}
			&.active {
				background: black;
				color: white;
			}
		}
	}
	&[style*="height:"] .menu__wrapper {
		overflow: hidden;
	}
}
.menu-product {
	display: flex;
	gap: 3rem;
	padding: calc(var(--bs-gutter-x) * .5);
	border-right: 2px solid $menu-border;
	border-bottom: 2px solid $menu-border;

	&:nth-child(#{$menu-columns}n) {
		border-right: none;
	}
	&:nth-child(#{$menu-columns}n+1):nth-last-child(-n+#{$menu-columns}),
	&:nth-child(#{$menu-columns}n+1):nth-last-child(-n+#{$menu-columns}) ~ .menu-product {
		border-bottom: none;
	}
	
	&__image {
		flex: 0 1 30%;
		text-align: right;

		img {
			width: 100px;
		}
	}
	&__details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex: 0 1 70%;
	}
	&__name {
		flex-grow: 0;
		flex-basis: 0;
		font-size: 18px;
		line-height: 1;
		font-weight: 300;
		text-transform: uppercase;
		
		a, a:hover {
			color: $black;
			text-decoration: none;
		}
		strong {
			display: block;
			font-weight: 900;
		}
	}
	&__link {
		flex: 0 0 0;
		margin-top: 7px;
		font-size: 16px;
		line-height: 1;
		font-weight: 300;
		
		a {
			display: inline-block;
			min-width: 120px;
			padding: 7px 12px;
			border: 1px solid $black;
			color: $black;
			text-align: center;
			text-decoration: none;
			transition: 300ms;
			
			&:hover {
				color: $black;
				background-color: darken($menu-bg, 10%);
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.menu {
		&__wrapper {
			max-height: calc(100% - 15px);
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}
@include media-breakpoint-down(xxl) {
	.menu-product {
		gap: 2rem;
	}
}
@include media-breakpoint-down(xl) {
	.menu-product {
		gap: 1rem;

		&__image {
			img {
				width: 80px;
			}
		}
		&__name {
			font-size: 16px;
		}
		&__link {
			font-size: 14px;

			a {
				min-width: 90px;
				padding: 5px 10px;
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.menu {
		position: absolute;
		top: 100%;
		right: 0;
		bottom: calc(-100vh + 100%);
		left: 0;
		display: flex;
		background-color: rgba($menu-bg, 0.95);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		z-index: 100;
		&__container {
			position: static;
			display: flex;
		}
		&__wrapper {
			position: relative;
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: flex-start;
			margin-top: 20px;
			margin-bottom: 20px;
			background: none;
			padding-bottom: 10vh;
			height: max-content;
			z-index: 200;
		}
		&__row {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: center;
		}
		&__products {
			grid-template-columns: 1fr;
			padding-bottom: 20px;
		}
	}
	.menu-product {
		position: relative;
		z-index: 1;
		padding: 20px 0;
		border: none !important;
		text-align: center;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: -1;
			left: calc(50% - 140px);
			width: 280px;
			border-top: 2px solid darken($menu-border, 5%);
		}

		&:last-of-type {
			&::before {
				border-bottom: 2px solid darken($menu-border, 5%);
			}
		}
		
		&__image {
			display: none;
		}
		&__details {
			align-items: center;
			flex-basis: 100%;
			padding: 0;
		}
		&__name {
			a:hover {
				color: darken($menu-border, 20%);
			}
		}
		&__link {
			display: none;
		}
	}
}
@include media-breakpoint-down(sm) {
	.menu {
		&__title {
			font-size: 24px;
		}
	}
	.menu-product {
		margin-right: calc(var(--bs-gutter-x) * .5);
		margin-left: calc(var(--bs-gutter-x) * .5);
		width: calc(100% - var(--bs-gutter-x));
	}
}

.languages {
	position: absolute;
	top: 100%;
	bottom: calc(-100vh + 100%);
	left: calc(50% + 536px);
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 0;
	height: auto !important;
	overflow: visible !important;

	&::before {
		$arrow-size: 12px;
		
		content: '';
		position: absolute;
		right: -#{$arrow-size};
		bottom: 100%;
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 $arrow-size $arrow-size $arrow-size;
		border-color: $nav-cart-btn transparent;
	}
	&__width-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 248px;
		height: auto;
		max-height: calc(100% - 15px);
		overflow: visible;
	}
	&__wrapper {
		flex-shrink: 0;
		width: max-content;
		max-height: 100%;
		padding: 7px 20px;
		background-color: $nav-cart-btn;
		overflow-y: auto;
		// -webkit-overflow-scrolling: touch;
	}
	&__element {
		display: block;
		color: $white;
		padding: 7px 0;
		text-align: center;
		font-size: 14px;
		line-height: 1.2;
		font-weight: 400;
		white-space: nowrap;

		&--current {
			margin-bottom: 3px;
			font-weight: 700;
			border-bottom: 1px solid rgba($white, 0.5);
		}
		&--link {
			opacity: 0.5;
			text-decoration: none;

			&:hover {
				color: $white;
				opacity: 1;
				text-decoration: underline;
			}
		}
	}
	&__name {
		text-transform: uppercase;
	}
}
@include media-breakpoint-down(xxl) {
	.languages {
		left: calc(50% + 446px);
	}
}
@include media-breakpoint-down(xl) {
	.languages {
		left: calc(50% + 356px);
	}
}
@include media-breakpoint-down(lg) {
	.languages {
		left: calc(50% + 236px);
	}
}
@include media-breakpoint-down(md) {
	.languages {
		left: calc(50% + 146px);
	}
}
@include media-breakpoint-down(sm) {
	.languages {
		left: calc(100% - 118px);

		&__width-container {
			max-width: 236px;
		}
	}
}

.flags {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 10;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: rgba($black, 0.4);
	font-size: 0;
	line-height: 0;

	a {
		display: inline-block;
		margin-right: 5px;
	}
}
@include media-breakpoint-down(lg) {
	.flags {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
@include media-breakpoint-down(sm) {
	.flags {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

.nav-cart {
	$gap: 14px;
	
	position: absolute;
	top: 100%;
	bottom: calc(-100vh + 100%);
	left: calc(50% + 634px);
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 0;
	height: auto !important;
	overflow: visible !important;
	color: $black;
	font-size: 16px;
	line-height: 1;
	font-weight: 300;
	&.mb {
		margin-top:-46px;
	}
	&::before {
		$arrow-size: 12px;
		
		content: '';
		position: absolute;
		right: -#{$arrow-size};
		bottom: 100%;
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 $arrow-size $arrow-size $arrow-size;
		border-color: $nav-cart-header transparent;
	}

	&__width-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 52px;
		height: auto;
		max-height: calc(100% - 15px);
		overflow: visible;
	}
	&__wrapper {
		flex-shrink: 0;
		min-width: 600px;
		max-height: 100%;
		background-color: $nav-cart-bg;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		&--empty {
			display: flex;
			align-items: center;
			min-width: 500px;
			background-color: $nav-cart-header;
			text-align: center;
			font-weight: 700;
		}
	}
	&__details {
		display: table;
		min-width: 100%;
		text-transform: uppercase;
	}
	&__row {
		display: table-row;

		&--header {
			background-color: $nav-cart-header;
		}
		&--product {
			text-transform: none;
		}
		&--footer {
			font-weight: 700;

			.old-price,
			.new-price {
				text-transform: none;
			}
		}

		.nav-cart__cell {
			border-bottom: 1px solid $nav-cart-footer;
		}
		&:first-child {
			.nav-cart__cell {
				padding-top: $gap;
				padding-bottom: $gap;
			}
		}
		&:first-child,
		&:last-child {
			.nav-cart__cell {
				border-bottom: none;
			}
		}
	}
	&__cell {
		display: table-cell;
		width: 100px;
		padding: math.div($gap, 2);
		vertical-align: middle;
		text-align: center;
		white-space: nowrap;

		&:first-child {
			width: unset;
			padding-left: $gap;
			text-align: left;
			white-space: normal;
			span{ 
				display: block;
				color: #FF7E00;
				font-size: 0.9em;
				width: 85%;
			}
		}
		&:last-child {
			padding-right: $gap;
		}
	}
	&__remove {
		position: relative;
		display: block;
		margin: 0 auto;
		width: 16px;
		height: 16px;
		padding: 0;
		border: none;
		background: none;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 1px);
			right: 0;
			left: 0;
			height: 2px;
			background-color: $black;
		}
		&::before {
			transform: rotateZ(45deg);
		}
		&::after {
			transform: rotateZ(-45deg);
		}

		&:active {
			right: -1px;
			left: 1px;
		}
	}
	&__footer {
		display: flex;
		background-color: $nav-cart-footer;
	}
	&__shipping {
		flex-grow: 1;
		padding: $gap;
	}
	&__button {
		flex-shrink: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 150px;
		padding: math.div($gap, 2) $gap;
		border: none;
		background-color: $nav-cart-btn;
		color: $white;
		text-decoration: none;
		
		&:hover {
			background-color: darken($nav-cart-btn, 10%);
			color: $white;
			text-decoration: none;
		}
	}
	&__empty {
		flex: 0 0 100%;
		padding: 30px 15px;
		text-align: center;
		font-size: 18px !important;
		font-weight: 700;
	}
}
@include media-breakpoint-down(xxl) {
	.nav-cart {
		left: calc(50% + 544px);
	}
}
@include media-breakpoint-down(xl) {
	.nav-cart {
		left: calc(50% + 454px);
	}
}
@include media-breakpoint-down(lg) {
	.nav-cart {
		left: calc(50% + 334px);
	}
}
@include media-breakpoint-down(md) {
	.nav-cart {
		left: calc(50% + 244px);
		font-size: 14px;

		&__wrapper {
			min-width: 540px;
		}
	}
}
@include media-breakpoint-down(sm) {
	.nav {
		&-cart {
			$gap: 10px;

			left: calc(100% - 23px);
			font-size: 12px;
	
			&::before {
				$arrow-size: 8px;
				
				right: -#{$arrow-size};
				border-width: 0 $arrow-size $arrow-size $arrow-size;
			}
	
			&__width-container {
				max-width: 46px;
				max-height: 100%;
			}
			&__wrapper {
				width: 100vw;
				min-width: 0;
			}
			&__row {
				&:first-child {
					.nav-cart__cell {
						padding-top: $gap;
						padding-bottom: $gap;
					}
				}
			}
			&__cell {
				width: 15%;
				padding: 7px math.div($gap, 2);
	
				&:first-child {
					padding-left: $gap;
				}
				&:last-child {
					padding-right: $gap;
				}
			}
			&__shipping {
				padding: $gap;
			}
			&__button {
				min-width: 0;
				padding: math.div($gap, 2) $gap;
			}
		}
	}
}

.order-repeated {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 1;
	padding: 5px 0;
	background: $red;
	background: linear-gradient(to bottom, $order-repeated-bg 0%, lighten($order-repeated-bg, 5%) 100%);
	color: $black;
	text-align: center;
	font-size: 1rem;
	line-height: 1;

	&--static {
		position: static;
		background: $lightgray;
		color: transparent;
	}
}
@include media-breakpoint-down(_sm, (_sm: 368px)) {
	.order-repeated {
		font-size: 0.8rem;
	}
}

@include media-breakpoint-down(_sm, (_sm: 390px)) {
	.nav {
		&__blog {
			font-size: 20px;
		}
	}
}

@include media-breakpoint-down(_sm, (_sm: 368px)) {
	.nav {
		&__blog {
			padding: 0 5px;
			letter-spacing: 1px;
		}
		&__logo {
			padding-left: 5px;
			flex-basis: 90px;
			img {
				height: 20px;
			}
		}
	}
}

@media (max-width: 991.98px) {
	html.menu-open {
		overflow-y: hidden;
	}
}
@media (max-width:440px){
	.nav-cart.mb {
		margin-top:-71px;
	}
	.nav .notification_promo .notification_text_inner{
		font-size:15px!important;
	}
}

