.gallery {
	position: relative;
	padding: 50px 0;

	&::before {
		$width: 100px;
		
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - math.div($width, 2));
		display: block;
		width: $width;
		height: 7px;
		background-color: $black;
	}
	
	h3 {
		@extend %title;
		text-align: center;
	}
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
		grid-template-rows: repeat(2, min-content) 0;
		margin: 20px auto 0;
		width: 100%;
		max-width: 1920px;
	}
	&__single {
		width: 100%;
		overflow: hidden;

		img {
			transform: scale(1.02);
			transition: ease 500ms;
			width: 100%;
			height: auto;
			&:hover {
				transform: scale(1.1) rotate(3deg);
			}
		}
	}
	&__description {
		margin: 50px auto;
		width: 80%;
		text-align: center;
	}
}
.gallery--no-border {
	&::before {
		content: none;
	}
}
@include media-breakpoint-down(lg) {
	.gallery {
		&__wrapper {
			grid-template-columns: repeat(8, 1fr);
		}
		// &__single {
		// 	&:nth-last-child(1) { display: none; }
		// 	&:nth-last-child(2) { display: none; }
		// 	&:nth-last-child(3) { display: none; }
		// 	&:nth-last-child(4) { display: none; }
		// }
		&__description {
			width: 90%;
		}
	}
}
@include media-breakpoint-down(md) {
	.gallery {
		&__wrapper {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: repeat(3, min-content) 0;
			margin: 10px auto 0;
		}
		// &__single {
		// 	&:nth-last-child(3) { display: block; }
		// 	&:nth-last-child(4) { display: block; }
		// }
		&__description {
			margin: 40px auto;
			width: 100%;
		}
	}
}
@include media-breakpoint-down(sm) {
	.gallery {
		padding: 30px 0;

		&::before {
			height: 5px;
		}
		
		&__wrapper {
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: repeat(4, min-content);
			margin: 0 auto;
		}
		// &__single {
		// 	&:nth-last-child(1) { display: block; }
		// 	&:nth-last-child(2) { display: block; }
		// }
		&__description {
			margin: 30px auto;
		}
	}
}