@use 'sass:math';

@import 'global';
@import 'includes/parallax';

@import 'components/products-grid';
@import 'components/variant-color';
@import 'components/gallery';
@import 'components/faq';
@import 'components/brand-banner';
@import 'components/social-media';
@import 'components/social-wall';
@import 'components/brands';
@import 'components/mark-section';

.newsletter {
	background-color: $semiblack;
	background-repeat: no-repeat;
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		width: 65%;
		height: 370px;
	}
	&__header {
		margin-bottom: 20px;
		width: 100%;
		color: $white;
		text-align: center;
		font-size: 2.2rem;
		line-height: 1.2;
		font-weight: 100;
	}
	&__form {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
	}
	&__input {
		flex: none;
		margin-bottom: 10px;
		width: 100%;
		padding: 7px 15px;
		font-size: 1.2rem;
		border: 1px solid rgba($white, 0.65);
		background: none;
		text-align: center;
		color: $white;
		font-weight: 300;
		transition: 300ms;

		&:focus {
			outline: none;
			border-color: $white;
		}
		&::placeholder {
			font-weight: 100;
		}

		&--invalid {
			border-color: rgba($red, 0.65);;
			background-color: rgba($red, 0.05);
			
			&:focus {
				border-color: $red;
			}
		}
	}
	&__submit {
		min-width: 200px;
		max-width: 100%;
		padding: 7px 15px;
		font-size: 1.2rem;
		font-weight: 300;
		border: 1px solid rgba($white, 0.65);
		background: $second-orange;
		color: $white;
		transition: 300ms;

		&:hover {
			background-color: rgba($second-orange, 0.85);
			// color: $semiblack;
		}
	}
	&__loader {
		@extend %loader;
		display: none;
		margin-top: 6px;
		margin-bottom: 6px;
		font-size: 32px;
	}
	&__message {
		display: none;
		color: $white;

		&--success {
			font-weight: 700;
		}
		&--error {
			opacity: 0.75;
		}
	}
}
@include media-breakpoint-down(xxl) {
	.newsletter {
		&__wrapper {
			width: 75%;
		}
	}
}
@include media-breakpoint-down(xl) {
	.newsletter {
		&__wrapper {
			width: 90%;
		}
	}
}
@include media-breakpoint-down(lg) {
	.newsletter {
		position: relative;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			display: block;
			background-image: url('#{$assets_path}img/parallax/home-parallax-front-small.webp');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: auto;
			opacity: 0.15;
		}
		
		&__wrapper {
			width: 60%;
		}
	}
	.NoSupportWebp {
		.newsletter {
			&::before {
				background-image: url('#{$assets_path}img/parallax/home-parallax-front-small.png');
			}
		}
	}
}
@include media-breakpoint-down(md) {
	.newsletter {
		&__wrapper {
			width: 70%;
			height: auto;
			padding: 50px 0;
		}
		&__header {
			font-size: 2rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.newsletter {
		&__wrapper {
			width: 100%;
			height: auto;
			padding: 30px 0;
		}
		&__header {
			width: 350px;
			max-width: 100%;
			font-size: 1.75rem;
		}
		&__input {
			width: 300px;
			max-width: 100%;
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.newsletter {
		&__wrapper {
			padding: 20px 0;
		}
		&__header {
			font-size: 1.5rem;
		}
		&__input {
			padding: 5px 10px;
			font-size: 1rem;
		}
		&__submit {
			padding: 5px 10px;
			font-size: 1rem;
		}
	}
}

.content {
	padding: 50px 0;
	text-align: center;
	
	&__wrapper {
		margin: 0 auto;
		width: 75%;
	}
	h3 {
		@extend %title;
	}
	p {
		font-weight: 400;

		&:last-child {
			margin-bottom: 0;
		}
	}
	strong {
		font-weight: 900;
	}
}
@include media-breakpoint-down(lg) {
	.content {
		&__wrapper {
			width: 90%;
		}
	}
}
@include media-breakpoint-down(md) {
	.content {
		&__wrapper {
			width: 100%;
		}
	}
}
@include media-breakpoint-down(sm) {
	.content {
		padding: 30px 0;
		
		p {
			text-align: left;
		}
	}
}

$presentation-square-width: 7px;
$presentation-line-thickness: 1px;

@mixin presentation-element-right {
	align-items: flex-end;
	text-align: right;
}
@mixin presentation-element-left {
	align-items: flex-start;
	text-align: left;
}
@mixin presentation-element-center {
	align-items: center;
	text-align: center;
}

@mixin presentation-line-direction-top {
	transform: rotateZ(270deg);
	transform-origin: math.div($presentation-square-width, 2);
}
@mixin presentation-line-direction-right {
	transform: rotateZ(0deg);
	transform-origin: math.div($presentation-square-width, 2);
}
@mixin presentation-line-direction-bottom {
	transform: rotateZ(90deg);
	transform-origin: math.div($presentation-square-width, 2);
}
@mixin presentation-line-direction-left {
	transform: rotateZ(180deg);
	transform-origin: math.div($presentation-square-width, 2);
}

@mixin presentation-line-position-top {
	top:    unset;
	bottom: calc(100% + var(--presentation-line-offset, 1em));
	right:  unset;
	left:   calc(50% - 0.5em);
}
@mixin presentation-line-position-right {
	top:    calc(50% - 0.5em);
	right:  unset;
	bottom: calc(50% - 0.5em);
	left:   calc(100% + var(--presentation-line-offset, 1em));
}
@mixin presentation-line-position-bottom {
	top:    calc(100% + var(--presentation-line-offset, 1em));
	right:  unset;
	bottom: unset;
	left:   calc(50% - 0.5em);
}
@mixin presentation-line-position-left {
	top:    calc(50% - 0.5em);
	right:  unset;
	bottom: calc(50% - 0.5em);
	left:   calc(0px - var(--presentation-line-offset, 1em) - $presentation-square-width);
}
.presentation {
	--presentation-line-offset: 10px;
	
	position: relative;
	padding: 50px 0;

	&::before {
		$width: 200px;
		
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - math.div($width, 2));
		display: block;
		width: $width;
		height: 7px;
		background-color: $black;
	}

	h3 {
		@extend %title;
		text-align: center;
	}
	&__wrapper {
		position: relative;
		padding: 50px 0;
	}
	&__image {
		display: block;
		margin: 0 auto;
	}
	&__element {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 250px;
		
		&.animation {
			animation: 1s forwards presentation-fade-in;
		}

		&--gel {
			@include presentation-element-right;
			top: 80px;
			right: calc(50% + 280px);
			--presentation-line-length: 230px;

			.presentation__line {
				@include presentation-line-position-right;
				@include presentation-line-direction-right;
			}
		}
		&--mascara {
			@include presentation-element-left;
			top: 30px;
			left: calc(50% + 280px);
			--presentation-line-length: 190px;

			.presentation__line {
				@include presentation-line-position-left;
				@include presentation-line-direction-bottom;
			}
		}
		&--serum {
			@include presentation-element-right;
			bottom: 20px;
			right: calc(50% + 200px);
			--presentation-line-length: 180px;

			.presentation__line {
				@include presentation-line-position-right;
				@include presentation-line-direction-top;
			}
		}
		&--soap {
			@include presentation-element-left;
			bottom: 90px;
			left: calc(50% + 300px);
			--presentation-line-length: 130px;

			.presentation__line {
				@include presentation-line-position-left;
				@include presentation-line-direction-left;
			}
		}
	}
	&__name {
		display: block;
		margin-bottom: 5px;
		color: $black;
		font-size: 1.5rem;
		line-height: 1;
		font-weight: 400;
		text-transform: uppercase;
		text-decoration: none;
		cursor: pointer;

		&:hover {
			color: $black;
		}
		
		strong {
			font-size: 1.75rem;
			font-weight: 900;
		}
	}
	&__group {
		position: relative;
	}
	&__button {
		display: block;
		padding: 10px 20px;
		border: 1px solid $gray;
		text-align: center;
		color: $gray;
		font-size: 1.15rem;
		line-height: 1;
		text-decoration: none;
		cursor: pointer;
		transition: 300ms;

		&:hover {
			color: $gray;
			background-color: rgba($black, 0.05);
		}
	}
	&__line {
		position: absolute;
		top:    calc(50% - 0.5em);
		right:  calc(50% - 0.5em);
		bottom: calc(50% - 0.5em);
		left:   calc(50% - 0.5em);
		width: var(--presentation-line-length, auto);
		height: 1em;
		font-size: $presentation-square-width;
		
		span {
			position: absolute;
			top:    calc(0.5em - math.div($presentation-line-thickness, 2));
			right:  0.5em;
			bottom: calc(0.5em - math.div($presentation-line-thickness, 2));
			left:   0.5em;
			background-color: $black;

			// right: 100%;
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				top:    calc(-0.5em + math.div($presentation-line-thickness, 2));
				bottom: calc(-0.5em + math.div($presentation-line-thickness, 2));
				width: 1em;
				height: 1em;
				background-color: $black;
			}
			&::before {
				right: unset;
				left: -0.5em;
			}
			&::after {
				right: -0.5em;
				left: unset;
			}

			&.animation {
				animation: 1s 400ms forwards presentation-slide-line;
			}
		}
	}
}
@include media-breakpoint-down(xxl) {
	.presentation {
		&__name {
			font-size: 1.35rem;

			strong {
				font-size: 1.5rem;
			}
		}
		&__button {
			font-size: 1.1rem;
		}
	}
}
@include media-breakpoint-down(xl) {
	.presentation {
		&__image {
			max-width: 475px;
		}
		&__element {
			width: 220px;

			&--gel {
				top: 70px;
				right: calc(50% + 180px);
				--presentation-line-length: 135px;
			}
			&--mascara {
				left: calc(50% + 230px);
				--presentation-line-length: 160px;
			}
			&--serum {
				bottom: 30px;
				right: calc(50% + 160px);
				--presentation-line-length: 160px;
			}
			&--soap {
				bottom: 80px;
				left: calc(50% + 240px);
				--presentation-line-length: 110px;
			}
		}
		&__name {
			font-size: 1.25rem;

			strong {
				font-size: 1.4rem;
			}
		}
		&__button {
			font-size: 1.05rem;
		}
	}
}
@include media-breakpoint-down(lg) {
	.presentation {
		&__wrapper {
			padding: 100px 0;
		}
		&__element {
			width: 175px;

			&--gel {
				top: 110px;
				right: calc(50% + 150px);
				--presentation-line-length: 95px;
			}
			&--mascara {
				left: calc(50% + 170px);
				--presentation-line-length: 110px;
			}
			&--serum {
				bottom: 50px;
				right: calc(50% + 140px);
				--presentation-line-length: 120px;
			}
			&--soap {
				bottom: 0;
				left: calc(50% + 50px);
				--presentation-line-length: 150px;

				.presentation__line {
					@include presentation-line-direction-top;
				}
			}
		}
		&__name {
			font-size: 1.1rem;

			strong {
				font-size: 1.25rem;
			}
		}
		&__button {
			padding: 7px 14px;
			font-size: 1rem;
		}
	}
}
@include media-breakpoint-down(md) {
	.presentation {
		&__element {
			width: 165px;

			&--gel {
				top: 30px;
				right: calc(50% + 65px);
				--presentation-line-length: 85px;

				.presentation__line {
					@include presentation-line-direction-bottom;
				}
			}
			&--mascara {
				@include presentation-element-center;
				top: 10px;
				left: calc(50% + 70px);
				--presentation-line-length: 90px;

				.presentation__group {
					position: static;
				}
				.presentation__line {
					@include presentation-line-position-bottom;
				}
			}
			&--serum {
				@include presentation-element-center;
				bottom: 30px;
				right: calc(50% + 40px);
				--presentation-line-length: 70px;

				.presentation__group {
					position: static;
				}
				.presentation__line {
					@include presentation-line-position-top;
				}
			}
			// &--soap {} // all the same
		}
		&__button {
			font-size: 0.9rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.presentation {
		padding: 30px 0;

		&::before {
			height: 5px;
		}
		
		&__wrapper {
			padding: 120px 0;
		}
		&__element {
			width: 135px;

			&--gel {
				top: 50px;
				right: calc(50% + 35px);
			}
			&--mascara {
				left: calc(50% + 30px);
				--presentation-line-length: 100px;
			}
			&--serum {
				bottom: 40px;
				right: calc(50% + 10px);
				--presentation-line-length: 90px;
			}
			&--soap {
				bottom: 10px;
				left: calc(50% + 30px);
			}
		}
		&__name {
			font-size: 0.9rem;

			strong {
				font-size: 1rem;
			}
		}
		&__button {
			padding: 5px 10px;
			font-size: 0.7rem;
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.presentation {
		--presentation-line-offset: 5px;
		
		&__image {
			max-width: 200px;
		}
		&__element {
			width: 120px;

			&--gel {
				right: calc(50% + 25px);
			}
			&--mascara {
				left: calc(50% + 10px);
				--presentation-line-length: 95px;
			}
			&--serum {
				right: 50%;
				--presentation-line-length: 85px;
			}
			&--soap {
				left: calc(50% + 20px);
				--presentation-line-length: 140px;
			}
		}
		&__name {
			font-size: 0.8rem;

			strong {
				font-size: 0.9rem;
			}
		}
	}
}

// animation: 500ms forwards presentation-slide-line;
@keyframes presentation-slide-line {
	from { right: 100%; }
	to { right: 0.5em; }
}
@keyframes presentation-fade-in {
	from { opacity: 0; }
	to { opacity: 1; }
}

.image-block {
	img {
	opacity: 1;
	transition: 0.2s;
		&.animate {
			opacity: 0.1;
			transition: 0.2s;
		}
	}
}

.header--home {
	background-color: #7c7c7c;
}
.header--home .header__container{
	padding-top: 40px;
}
.header__content h2, .header__content .h2 {
	font-size: 2.5rem;
}
.header__content h3, .header__content .h3 {
	font-size: 1.75rem;
	font-weight: 100;
}
.header__content p.big {
	margin-left: 25px;
}
.header--home__list {
	padding: 20px 20px 20px 45px;
	background: #222;
	background: linear-gradient(to right, #222 0%, #6f6f6f 100%);
	list-style: square;
}
.header--home__list li {
	font-size: 1rem;
	line-height: 1.5;
	padding-left: 4px;
}
.header--border::after {
	content: none;
}
.header--home__border {
	background-color: #222;
}
.header--home__border .icon-block {
	display: flex;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
}
.header--home__border img {
	width: 100px; 
	height: auto;
}
.header--home__border span {
	color: #fff;
}

@media screen and (max-width: 1199.98px) {
	.header__content h2, .header__content .h2 {
		font-size: 2rem;
	}
}

@media screen and (max-width: 991.98px) {
	.header__content h2, .header__content .h2 {
		font-size: 1.75rem;
		background: #222;
		background: linear-gradient(to right, #222 0%, #6f6f6f 100%);
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.header__content h2:before, .header__content .h2:before {
		content: none;
	}
	.icon-block {
		margin-left: auto;
		margin-right: auto;
	}
	.header--home__border img {
		width: 75px;
	}
	.header--home__border span {
		font-size: 0.75rem;
	}
}
@media screen and (max-width: 767.98px) {
	.header__content h2, .header__content .h2 {
		font-size: 1.25rem;
	}
	.header--home__border img {
		width: 50px;
	}
	.header-content .header--home__title {
		padding-left: unset;
		font-size: 1.5rem;
	}
	.header-content .header--home__title:before {
		content: none;
	}
}
@media screen and (min-width: 992px) {
	.icon-row {
		justify-content: space-around;
	}
}

.products-grid__price {
	font-size: 1.25rem;
}
.products-grid__price .old-price {
    font-size:0.6em;
    margin-right:0.6em;
}
.products-grid__content:hover .products-grid__backdrop {
	visibility: hidden;
	opacity: 0;
}
.qnt-counter .qnt-counter__input {
	width:1.5em;
}