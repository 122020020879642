.brand {
    &-title {
        color: #E1DFDF;
        text-decoration: none;
        font-size: 13px;
        padding-bottom: 0px;
        text-transform: uppercase;
        font-weight: 300;
        text-align: center;
    }
}

.brands {
    background-color: $black;
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 1.5rem 0;
        gap: 3rem;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            top: 25%;
            bottom: 25%;
            width: 1px;
            background-color: #E1E1E1;
        }
    }
    &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: initial;
        text-decoration: none;
        &:hover {
            img {
                filter: brightness(1);
            }
        }
        img {
            max-width: 100%;
            filter: brightness(0.5);
            transition: filter 300ms ease-out;
            margin-top: auto;
        }
        &.current {
            img {
                filter: brightness(1);
            }
        }
    }
}

.brands {
    &.margin {
        margin: 5rem 0;
        z-index: -1;
    }
    &-grid {
        &-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            .brands-column {
                grid-column: 1/3;
                img {
                    margin-top: unset;
                }
            } 
            &-image-block {
                display: none;
                position: relative;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (min-width:992px) {
    .brands {

        &-grid {
            &-item {
                .brands-column {
                    grid-column: 2/3;
                }
                &-image-block {
                    display: block;
                    img {
                        position: absolute;
                        top: -100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:567px) {
    .brands {
        &-grid {
            gap : 1rem;
        }
    }
    .brands-navigation{
        .brands-grid {
            padding: 1.5rem 0.5rem;
            gap:1rem;
        }
    }
    .brands.margin {
        margin:1rem 0;
    }
}