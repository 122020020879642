.social-media {
	margin-top: auto;
	padding: 40px 0;

	&__header {
		text-align: center;
		font-size: 2rem;
		line-height: 1;
		font-weight: 100;
	}
	&__wrapper {
		display: flex;
		justify-content: center;
	}
	&__icons {
		display: flex;
		justify-content: center;
		gap: 10px;
		margin: 20px 0 0;
		padding: 0;
		list-style-type: none;
	}
	&__element {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 32px;
		border: 1px solid darken($gray, 20%);
		text-align: center;
	}
	&__icon {
		display: block;
		padding: 5px;
		color: darken($gray, 20%);
		font-size: 32px;
		line-height: 1;
		text-decoration: none;
		cursor: pointer;
		transition: 300ms;

		&:hover {
			background-color: darken($gray, 20%);
			color: $white;
		}
	}
}
@include media-breakpoint-down(sm) {
	.social-media {
		padding: 30px 0;

		&__header {
			font-size: 1.2rem;
		}
		&__icons {
			margin-top: 10px;
		}
		&__element {
			flex-basis: 20px;
		}
		&__icon {
			font-size: 20px;
		}
	}
}
@include media-breakpoint-down(_sm, (_sm: 420px)) {
	.social-media {
		padding: 15px 0;
	}
}

.facebook {
	&:hover {
		i {
			color: #087AE9;
		}
	}
}
.instagram {
	&:hover {
		i {
			background: linear-gradient(45deg, #f4b651 33%, #ca2d8e 66%, #a332ae);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: #dc2b6d;
		}
	}
}
.tiktok {
	&:hover {
		i {
			color: #000;
			text-shadow: -1px -1px 0 #00f3ee;
		}
	}
}
.youtube {
	&:hover {
		i {
			color: #ff0000;
		}
	}
}
.pinterest {
	&:hover {
		i {
			color: #b6071b;;
		}
	}
}

.social-media-section {
	display: flex;
	gap: 0 10px;
	a {
		color: inherit;
		font-size: 3rem;
		text-decoration: none;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.5s;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	
}

.soicial-media-brand {
	display: flex;
	gap: 0 10px;
	a {
		color: inherit;
		font-size: 1.5rem;
		text-decoration: none;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.5s;
		border: 1px $black solid;
		padding: 5px;
	}
}

@include media-breakpoint-up(sm) {
	.soicial-media-brand {
		a {
			font-size: 1.75rem;
		}
	}
}

@include media-breakpoint-up(md) {
	.soicial-media-brand {
		a {
			font-size: 2rem;
		}
	}
}

@include media-breakpoint-up(lg) {
	.soicial-media-brand {
		a {
			font-size: 2.5rem;
			border: 2px $black solid;
		}
	}
}

@include media-breakpoint-up(xxl) {
	.soicial-media-brand {
		a {
			font-size: 3rem;
			border: 2.75px $black solid;
		}
	}
}

@media screen and (max-width:567px) {
	.social-section {
		flex-direction: column;
    	align-items: center;
		gap: 10px;
	}
	.social-section > img {
		height: 50px;
    	margin: 1rem 0;
	}
	.social-section .social-media-section {
		gap: 0 30px;
	}
}